import { Link } from "react-router-dom";
import { useContext, useEffect } from 'react';
import { NavContext, ThemeContext } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { bgGrayColor700, bgRedColor, bgBlueColor, borderDarkColor, borderGrayColor, txtDarkColor, txtWhiteColor, txtRedColor, txtBlueColor, txtGrayColor } from "../style/colors";

const AtcDocumentation = () => {

    const setActiveNav = useContext(NavContext).setActiveNav;
    const darkMode = useContext(ThemeContext).darkMode;

    // set global variable for active page
    useEffect(() => {
        setActiveNav('atc');
    }, []);

    return(
        <div className="flex justify-center content-center pb-10">
            <div className={`w-screen sm:w-full py-5 px-10 ${darkMode ? txtGrayColor : txtDarkColor}`}>
                <section className="w-full flex justify-center content-center">
                <div className="w-full sm:w-10/12 md:w-8/12 lg:w-6/12 flex flex-col justify-center content-center">
                        <h1 className="text-center text-xl md:text-2xl font-bold pt-6 pb-2">ATC Documents</h1>
                        <div className="flex justify-center content-center mb-5"><div className={`w-16 h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div></div>
                        <div className={`flex flex-col justify-center content-center text-sm md:text-base xl:text-lg border rounded shadow-md shadow-gray-600/40 mb-6 ${darkMode ? borderGrayColor : borderDarkColor}`}>
                            <table className="table">
                                <thead>
                                    <tr className={`text-lg font-bold ${txtWhiteColor} ${bgGrayColor700}`}>
                                        <th className={`border-e ${borderGrayColor}`}>Name</th>
                                        <th className="w-28">Download</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center font-bold">
                                    <tr className={`h-10`}>
                                        <td className={`border-e ${darkMode ? borderGrayColor : borderDarkColor}`}>Cairo FIR TMAs</td>
                                        <td><Link to="https://eg.ivao.aero/download/data/Egypt_TMAs_V1.1.pdf" target="_blank"><FontAwesomeIcon className={`text-2xl ${darkMode ? txtRedColor : txtBlueColor} py-2 px-4 hover:animate-pulse`} icon={faFileArrowDown} /></Link></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default AtcDocumentation;