import { useContext } from 'react';
import { ThemeContext } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCaretUp } from '@fortawesome/free-solid-svg-icons'
import { hoverTxtLightBlueColor, hoverTxtRedColor, txtBlueColor, txtWhiteColor, bgDarkGrayColor, bgWhiteGrayColor} from '../style/colors';

const ScrollToTop = () => {
    const darkMode = useContext(ThemeContext).darkMode;

    function scrollToTop(){
        window.scrollTo({
            top:0,
            behavior: 'smooth',
        });
    }

    const hideButtonBackground = () => {
        document.querySelector('.scrollBtnBackground').style.visibility = "hidden";
    }
    const showButtonBackground = () => {
        document.querySelector('.scrollBtnBackground').style.visibility = "visible";
    }

    return(
        <div onClick={scrollToTop} className={`z-40 fixed flex justify-center items-center bottom-0 right-0 rounded me-3 mb-0.25 cursor-pointer`}>
            <div className={`scrollBtnBackground h-9 w-10 absolute rounded opacity-80 blur-sm ${darkMode ? bgDarkGrayColor : bgWhiteGrayColor}`}></div>
            <div onMouseOver={hideButtonBackground} onMouseLeave={showButtonBackground} className={`z-40 text-4xl ${darkMode ? txtWhiteColor : txtBlueColor} ${darkMode ? hoverTxtRedColor : hoverTxtLightBlueColor} hover:scale-105 transition duration-200 ease-in-out`}><FontAwesomeIcon icon={faSquareCaretUp} /></div>
        </div>
    );

}

export default ScrollToTop;