import { txtWhiteColor, txtBlueColor } from "../style/colors";

const IvaoEgLogo = (props) => {
    const {darkMode} = props.data;
    const ComponentType = props.type;
    
    if(ComponentType === "footer"){
        return (
            <div className="eg-logo flex justify-center content-center">
                <img className="w-14 me-4" src={`${darkMode ? "EG.svg" : "EG-white.svg"}`} alt="ivao-eg-logo" />
                <div className="flex flex-col justify-center content-center">
                    <div className={`${darkMode ? txtBlueColor : txtWhiteColor} flex justify-center content-center`}>
                        <h2 className="text-logoxl font-bold uppercase">IVAO</h2>
                        <h3 className="font-bold uppercase ms-0.5 pt-[0.02rem]">EG</h3>
                    </div>
                </div>
            </div>
        );
    }else{
        return (
            <div className="eg-logo flex justify-center content-center">
                <img className="w-14 me-4" src={`${darkMode ? "EG-white.svg" : "EG.svg"}`} alt="ivao-eg-logo" />
                <div className="flex flex-col justify-center content-center">
                    <div className={`${darkMode ? txtWhiteColor : txtBlueColor} flex justify-center content-center`}>
                        <h2 className="text-logoxl font-bold uppercase">IVAO</h2>
                        <h3 className="font-bold uppercase ms-0.5 pt-[0.02rem]">EG</h3>
                    </div>
                </div>
            </div>
        );
    }
}

export default IvaoEgLogo;