import { useContext, useState, useEffect } from 'react';
import { NavContext, ThemeContext } from '../App';
import { Link } from "react-router-dom";
import { bgDarkColor, bgDarkGrayColor, bgWhiteColor, bgWhiteGrayColor, borderGrayColor, hoverBGDarkGrayColor, hoverBGWhiteGrayColor, hoverTxtDarkColor, hoverTxtGrayColor, txtDarkColor, txtGrayColor, txtLightBlueColor, txtRedColor, txtWhiteColor } from '../style/colors';

const AtcTrainingExam = () => {
  const setActiveNav = useContext(NavContext).setActiveNav;
  const darkMode = useContext(ThemeContext).darkMode;

  // Menu states
  const [firstSteps, setFirstSteps] = useState(true);
  const [rating, setRating] = useState(false);
  const [documentation, setDocumentation] = useState(false);
  const [exams, setExams] = useState(false);

  // set global variable for active page
  useEffect(() => {
    setActiveNav('atc');
  }, []);

  // Reset all menu states on click function
  function handleClick(){
      setFirstSteps(false);
      setRating(false);
      setDocumentation(false);
      setExams(false);
  }

  return (
    <div className="flex justify-center content-center pb-10">
      <div className={`w-screen sm:w-full ${darkMode ? txtGrayColor : txtDarkColor }`}>
        <div className="w-full flex flex-col sm:flex-row">
          <div className="w-full sm:min-w-56 sm:max-w-56 h-full navMenu mb-8">
            <ul className={`w-full select-none  ${darkMode ? bgDarkGrayColor : bgWhiteGrayColor}`}>
              <li className={`atcMenuItem cursor-pointer h-10 lg:h-12 font-bold text-center flex flex-col justify-center border-y border-x ${borderGrayColor} ${darkMode ? hoverBGWhiteGrayColor +" "+hoverTxtDarkColor : hoverBGDarkGrayColor+" "+hoverTxtGrayColor} ${firstSteps ? (darkMode ? bgWhiteGrayColor+" "+txtDarkColor : bgDarkGrayColor+" "+txtGrayColor) : (darkMode ? bgDarkColor : bgWhiteColor)}`} onClick={() => {handleClick(); setFirstSteps(true)}}>CONTROLLER FIRST STEPS</li>
              <li className={`atcMenuItem cursor-pointer h-10 lg:h-12 font-bold text-center flex flex-col justify-center border-b border-x ${borderGrayColor} ${darkMode ? hoverBGWhiteGrayColor +" "+hoverTxtDarkColor : hoverBGDarkGrayColor+" "+hoverTxtGrayColor} ${rating ? (darkMode ? bgWhiteGrayColor+" "+txtDarkColor : bgDarkGrayColor+" "+txtGrayColor) : (darkMode ? bgDarkColor : bgWhiteColor)}`} onClick={() => {handleClick(); setRating(true)}}>CONTROLLER RATINGS</li>
              <li className={`atcMenuItem cursor-pointer h-10 lg:h-12 font-bold text-center flex flex-col justify-center border-b border-x ${borderGrayColor} ${darkMode ? hoverBGWhiteGrayColor +" "+hoverTxtDarkColor : hoverBGDarkGrayColor+" "+hoverTxtGrayColor} ${documentation ? (darkMode ? bgWhiteGrayColor+" "+txtDarkColor : bgDarkGrayColor+" "+txtGrayColor) : (darkMode ? bgDarkColor : bgWhiteColor)}`} onClick={() => {handleClick(); setDocumentation(true)}}>DOCUMENTATION</li>
              <li className={`atcMenuItem cursor-pointer h-10 lg:h-12 font-bold text-center flex flex-col justify-center border-b border-x ${borderGrayColor} ${darkMode ? hoverBGWhiteGrayColor +" "+hoverTxtDarkColor : hoverBGDarkGrayColor+" "+hoverTxtGrayColor} ${exams ? (darkMode ? bgWhiteGrayColor+" "+txtDarkColor : bgDarkGrayColor+" "+txtGrayColor) : (darkMode ? bgDarkColor : bgWhiteColor)}`} onClick={() => {handleClick(); setExams(true)}}>CONTROLLER EXAMS</li>
            </ul>
          </div>
          <div className="w-full h-full">
            <section className="w-full flex justify-center content-center p-6">
              <div className="eael-tabs-content text-xs sm:text-sm md:text-base">
                {firstSteps && <div>
                  <Link to="https://wiki.ivao.aero/en/home/devops/manuals/aurora-first-steps" target='_blank'>
                    <h2 className={`text-xl lg:text-2xl font-bold mb-3 hover:underline ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                      CONTROLLER FIRST STEPS
                    </h2>
                  </Link>
                  <div className='mb-8 ps-3'>
                    <p className='mb-3'>
                        This tutorial will show you the steps required to join IVAO as a virtual Air Traffic Controller (ATC). 
                        It is meant to give you a short overview about the tools used, and some very basic instructions how to “behave” in this new environment.
                    </p>
                    <p className='mb-3'>
                      If you have not yet done so, you will need to{" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://ivao.aero/members/person/register.htm"
                        target="_blank"
                      >
                        join IVAO
                      </Link>{" "}
                      to create your personal user account.
                    </p>
                    <p className="text-info mb-3">
                      Note that this is required only once, and valid for both
                      ATC and pilot career!
                    </p>
                    <p>More specifically, we will show you how to…</p>
                    <ul className='list-disc ps-8'>
                      <li className="level1">
                        <div className="li">
                          Download, install and set up the required software
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">Add and load a sector file for your favourite airport</div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          Connect to the IVAO Network (“Aurora”)
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                            Set up an ATIS
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          Obtain further information and training
                        </div>
                      </li>
                    </ul>
                  </div>
                  <h3 id="download_ivap" className={`sectionedit2 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    Download Aurora
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                      The first thing you need to do is download our ATC
                      client, Aurora, which will allow you to connect to the
                      network. This can be downloaded from {" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://ivao.aero/softdev/software.asp"
                        target="_blank"
                      >
                        IVAO Software
                      </Link>
                      .
                    </p>
                    <p className='mb-3'>
                      Once you have downloaded the program, you should run the
                      executable file and install the program.
                    </p>
                  </div>
                  <h3 id="mtl_multiplayer_traffic_library" className={`sectionedit3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    OBTAIN AND LOAD A SECTOR FILE
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                        A sector file is the “map” displayed on your radar screen. 
                        It contains data layers with information about airport location and layout, navigational data like navigational aids, navigation fixes and airways as well as airspace boundaries. 
                        Depending on the detail provided, it also shows geographical information like runway/taxiway layout at an airport or coastlines, rivers and roads.
                    </p>
                    <p className='mb-3'>
                        To work at an ATC position, it is essential for you to obtain such a sector file. 
                        Most of the time, your local division will have prepared one already and offer it for download on their web site.
                    </p>
                    <p className="text-info">
                        Once you have downloaded your sectorfile, you have to load it with your Aurora. 
                        Press the PVD button and then Load SectorFile and select the appropriate sectorfile.
                    </p>
                  </div>
                  <h3 id="first_connection" className={`sectionedit5 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    FIRST CONNECTION
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                        We will now connect you as an Observer at your favourite airport. As an Observer, you are free to watch the traffic fly around and you may listen to active ATC. 
                        You have no duties, that means you are not in charge of any traffic yet.
                    </p>
                    <p className='mb-3'>Load Aurora and open the desired sector file, as described in the step above.</p>
                    <p className='mb-3'>
                        Your first connections should be as an Observer, without actual ATC duties. This will help you to make yourself familiar with the software and IVAO while avoiding trouble with pilots who will contact you for a clearance sooner or later. Use the chance to listen in on active ATC frequencies and learn!
                    </p>
                    <p className='mb-3'>
                        Now, press the CONNECT button.
                    </p>
                    <p className='mb-2'>Enter the details in the connection window:</p>
                    <ul className='list-disc ps-8'>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>Callsign:</strong> Your callsign. At this time, you will connect as an Observer, so the callsign can be anything, but must end with “_OBS”. 
                          We suggest you use your division abbreviation followed by your initials, for example: ZA_MR_OBS.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>Real Name:</strong> Your full name that you registered with IVAO.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>VID:</strong> Your IVAO VID that was assigned
                          to you when you joined.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>Password:</strong> The Website password you were
                          assigned when you joined IVAO.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>Server Address:</strong> The server you are connecting
                          to. For best performance, use the one auto selected. Do not use the Training server. 
                          It is for scheduled training sessions only!
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>Port:</strong> Leave this box as it is!
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>Voice:</strong> Select on to have Aurora connect you to a TeamSpeak server automatically.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                            Once all of the above details are entered, press the Connect button.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                            When you have successfully connected, you will receive a welcome message in the MSG tab of Aurora's COMMBox, and the Connect button will now read Disconnect.
                        </div>
                      </li>
                    </ul>
                  </div>
                  <h3 id="flight_planning" className={`sectionedit6 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    CONNECTING AS AIR TRAFFIC CONTROLLER
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                        Your first connection as an Air Traffic Controller on the IVAO network requires a basic understanding of the procedures employed at the place you will control. Check your division's website for those procedures and charts for the aerodrome.
                    </p>
                    <p className='mb-2'>
                        Some important things to consider before connecting to the network:
                    </p>
                    <ul className='list-disc ps-8 mb-3'>
                        <li className="level1 pb-2">
                            <div className="li">
                                Before connecting as an active ATC station, make sure you have the proper sector file and required documents and charts ready. Pilots will rely on you having a basic knowledge of your airspace and airport as well as its procedures.
                            </div>
                        </li>
                        <li className="level1 pb-2">
                            <div className="li">
                                For your first steps it is strongly recommended that you connect as a Ground or Tower Controller at a less crowded place (which might not be your favourite airport, and clearly not the fancy big international field of your capital!) to get used to your new environment and duties.
                            </div>
                        </li>
                    </ul>
                    <p className='mb-2'>
                        Once you have prepared those items, you can attempt a connection. Use the steps as provided in the chapter “First Connection”, with these changes:
                    </p>
                    <ul className='list-disc ps-8 mb-3'>
                        <li className="level1 pb-2">
                            <div className="li">
                                <strong>Callsign:</strong> Enter [ICAO]_GND (for a Ground Position) or [ICAO]_TWR (for a Tower Position) accordingly. Replace [ICAO] with the proper ICAO-Code of your airfield.
                            </div>
                        </li>
                        <li className="level1 pb-2">
                            <div className="li">
                                <strong>Voice:</strong> As it is highly recommended to use voice procedures, tick the box and select a suitable server.
                            </div>
                        </li>
                    </ul>
                    <p className='mb-3'>
                        Once all fields are completed, press the Connect button.
                    </p>
                    <p className='mb-2'>
                    Switch Aurora to the proper frequency. Pilots will dial your ATC station's published frequency to contact you. Make sure IvAc is tuned to it as well! To do so, select the first tab in the COMMBox and type.
                    </p>
                  </div>
                  <h3 id="interaction_with_atc" className={`sectionedit7 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    SET UP AN ATIS
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                        The most important information about an aerodrome is included in the ATIS. 
                        “ATIS” stands for Automated Terminal Information Service. As an ATIS transmits important status information about an airfield, it is important to set it up properly. 
                        The weather data is included automatically. You should add runway information (the current runway in use for departures and arrivals), transition altitude/transition level and if necessary some important information (like “Runway 03R closed” or “SPA VOR unserviceable”).
                    </p>
                    <p className='mb-3'>
                        Even though runways are mainly assigned by wind direction, and the transition values can be obtained from charts, actual procedures might differ. 
                        You should therefore seek information from your local division about standard procedures beforehand!
                    </p>
                  </div>
                  <h3 id="interaction_with_atc" className={`sectionedit7 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    INTERACTION WITH PILOTS
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                        In general, the two most basic ATC positions here on IVAO are “Ground” (xxxx_GND) and “Tower” (xxxx_TWR) positions. 
                        As a Ground controller you will be responsible for all the airplanes moving on your aerodrome surface, excluding the runways. 
                        This means that your duties will be to issue the IFR clearances and guide the airplanes from the gate to the runway and the opposite in an safe, regular and expeditious manner. 
                        As a Tower controller you are responsible for the airplanes taking off or landing at your aerodrome, and the airplanes flying in the vicinity of your aerodrome. 
                        This means that your duties will be to assign take off or landing clearances and to separate the airplanes from each other which are flying in the immediate vicinity of your airport. 
                        For more details of your duties as controller to the various ATC positions check, for example, the UK Training Web Site. 
                        Examples of the phraseology used during communications can be found as well in the above link.
                    </p>
                    <p className='mb-3'>
                        As a controller here on IVAO, you will use your IvAc screen to “see” the online traffic, and use voice or text to communicate with them. 
                        Below you can see an example of what your IvAc screen will look like.
                    </p>
                    <ul className='list-disc ps-8 mb-3'>
                        <li className="level1 pb-2">
                            <div className="li">
                                The Flight Strip. When you select a flight, it will show a collection of the most important parts of the flight plan (origin, destination, route, requested cruising flight level etc.).
                            </div>
                        </li>
                        <li className="level1 pb-2">
                            <div className="li">
                                This is how every flight looks like on your radar screen – the radar target. It is composed of a small square (the actual position of the aircraft) and its label. The label consists of the most important actual information of this aircraft (current ground speed and altitude, type of aircraft and others).
                            </div>
                        </li>
                        <li className="level1 pb-2">
                            <div className="li">
                            The COMMbox. Through the commbox, you can communicate with the pilots using text, retrieve the airport METARs, talk via private chat with other pilots or controllers (and more!).
                            </div>
                        </li>
                    </ul>
                    <p className="text-info mb-3">
                        Before you start your career as a virtual ATC, you could join the channel of other controllers and listen to the communications. This will make you more familiar to the controller's phraseology!
                    </p>
                  </div>
                  <h3 id="further_information" className={`sectionedit9 mb-3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    FURTHER INFORMATION
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                        You may find our IVAO Forum (opens in a new window for better readability) incredibly helpful. A local division forum is also available, select it from the drop-down menu at the top. You can use it to ask any questions or post about any problems you are having. 
                        There are lots of people willing and able to help you make the most out of your online flying experience.
                    </p>
                    <p className='mb-3'>
                    In addition to the forums, you may find useful information and procedures for ATC service on your division's web site. Go there by visiting <span className='font-bold'>http://xx.ivao.aero/</span> (replace “xx” with the two-letter code of your division). If you are unsure about the correct code, visit your{" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://ivao.aero/members/person/details.asp"
                        target="_blank"
                      >
                        personal profile
                      </Link>{" "}
                        page – it is listed there.
                    </p>
                  </div>
                  <h3 id="training" className={`sectionedit10 mb-3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    TRAINING
                  </h3>
                  <div className="level3 mb-8 ps-3">
                        <p className='mb-3'>
                            If you would like to extend your understanding with regards to virtual ATC, you may find it beneficial to request a training session from your local division's training department. 
                            This can be done via the training section of your division website (see previous paragraph for the link setup).
                        </p>
                  </div>
                  <h3 id="and_finally" className={`sectionedit11 mb-3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    AND FINALLY…
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p>
                        Have fun! This is an incredibly addictive hobby. As soon as you start doing ATC online, you will never go back to offline ATC simulations and will wonder why you didn't do it sooner!
                    </p>
                  </div>{" "}
                  {/* Load Images to improve performance */}
                  <div className="hidden static bottom-0 flex justify-end">
                        <img src="images/atc/rating/2.webp" className="w-1" alt="imgload"></img>
                        <img src="images/atc/rating/3.webp" className="w-1" alt="imgload"></img>
                        <img src="images/atc/rating/4.webp" className="w-1" alt="imgload"></img>
                        <img src="images/atc/rating/5.webp" className="w-1" alt="imgload"></img>
                        <img src="images/atc/rating/6.webp" className="w-1" alt="imgload"></img>
                        <img src="images/atc/rating/7.webp" className="w-1" alt="imgload"></img>
                        <img src="images/atc/rating/8.webp" className="w-1" alt="imgload"></img>
                        <img src="images/atc/rating/9.webp" className="w-1" alt="imgload"></img>
                        <img src="images/atc/rating/10.webp" className="w-1" alt="imgload"></img>
                  </div>
                </div>}

                {rating && <div
                  id="atc-ratings-tab"
                  className="clearfix eael-tab-content-item inactive"
                  data-title-link="pilot-ratings-tab"
                >
                  <h2 id="atc" className={`sectionedit1 mb-3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    ATC RATINGS
                  </h2>
                  <div className="level2 mb-8 ps-3">
                    <p className='mb-3'>
                        Ratings reflect the amount of practice that you have completed with IVAO, based on time spent at the radar scope and number of successfully completed exams.
                    </p>
                    <p className='mb-3'>
                        As soon as you have collected enough hours of practise and training, you can apply for the theoretical and practical exam of the next higher rating. 
                        The ratings are done in the sequence shown, you cannot jump over an intermediate rating.
                    </p>
                    <p className='mb-3'>
                        The IVAO Training Department establishes minimum standards for each rating level. 
                        Your local division may require additional higher standards.
                    </p>
                    <p className='mb-3'>
                      This table shows which ratings are available at IVAO, and
                      which requirements you will have to fulfill to attain a
                      rating. Your current rating is shown in your{" "}
                      <Link
                        to="https://ivao.aero/members/person/details.asp"
                        target="_blank"
                      >
                        IVAO Profile
                      </Link>{" "}
                      under ATC Info”.
                    </p>
                    <div className="table sectionedit2 border rounded border-gray-700">
                      <table className="inline text-center">
                        <thead className=''>
                          <tr className="h-12 w-full row0">
                            <th colSpan='7'>
                              <h2 className='text-center'>Requirements</h2>
                            </th>
                          </tr>
                          <tr className="row1 border-y border-gray-600">
                            <th className="col0 border-x border-gray-600">Badge</th>
                            <th className="col1 border-e border-gray-600">Title</th>
                            <th className="col2 border-e border-gray-600">Minimum Hours</th>
                            <th className="col3 border-e border-gray-600">Theoretical Exam</th>
                            <th className="col4 border-e border-gray-600">Practical Exam</th>
                            <th className="col5 border-e border-gray-600">Briefing Guide</th>
                            <th className="col6">Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="row2 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/atc/rating/2.webp' alt='ATC Applicant'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                                ATC Applicant (AS1)
                            </td>
                            <td className="col2 border-e border-gray-600">0</td>
                            <td className="col3 border-e border-gray-600">–</td>
                            <td className="col4 border-e border-gray-600">–</td>
                            <td className="col5 border-e border-gray-600">Not required</td>
                            <td className="col6 text-start ps-2">
                              Rating given when registering with IVAO.
                            </td>
                          </tr>
                          <tr className="row3 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/atc/rating/3.webp' alt='ATC Trainee'/>
                            </td>
                            <td className="col1 border-e border-gray-600">ATC Trainee (AS2)</td>
                            <td className="col2 border-e border-gray-600">10</td>
                            <td className="col3 border-e border-gray-600">–</td>
                            <td className="col4 border-e border-gray-600">–</td>
                            <td className="col5 border-e border-gray-600">Not required</td>
                            <td className="col6 text-start ps-2">
                              Rating automatically achieved after completing the
                              minimum hours.
                            </td>
                          </tr>
                          <tr className="row4 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/atc/rating/4.webp' alt='Advanced ATC Trainee'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                                Advanced ATC Trainee (AS3)
                            </td>
                            <td className="col2 border-e border-gray-600">25</td>
                            <td className="col3 border-e border-gray-600">✓</td>
                            <td className="col4 border-e border-gray-600">–</td>
                            <td className="col5 border-e border-gray-600">
                              Refer to the IVAO documentation
                            </td>
                            <td className="col6 text-start ps-2">
                              Theoretical exam in english
                            </td>
                          </tr>
                          <tr className="row5 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/atc/rating/5.webp' alt='Aerodrome Controller'/>
                            </td>
                            <td className="col1 border-e border-gray-600">Aerodrome Controller (ADC)</td>
                            <td className="col2 border-e border-gray-600">50</td>
                            <td className="col3 border-e border-gray-600">✓</td>
                            <td className="col4 border-e border-gray-600">✓</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="http://mediawiki.ivao.aero/index.php?title=Aerodrome_Controller_briefing"
                                target="_blank"
                              >
                                ADC Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 text-start ps-2">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row6 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/atc/rating/6.webp' alt='Approach Controller'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                                Approach Controller (APC)
                            </td>
                            <td className="col2 border-e border-gray-600">100</td>
                            <td className="col3 border-e border-gray-600">✓</td>
                            <td className="col4 border-e border-gray-600">✓</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="http://mediawiki.ivao.aero/index.php?title=Approach_Controller_briefing"
                                target="_blank"
                              >
                                APC Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 text-start ps-2">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row7 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/atc/rating/7.webp' alt='Centre Controller'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                                Centre Controller (ACC)
                            </td>
                            <td className="col2 border-e border-gray-600">200</td>
                            <td className="col3 border-e border-gray-600">✓</td>
                            <td className="col4 border-e border-gray-600">✓</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="http://mediawiki.ivao.aero/index.php?title=Area_Controller_briefing"
                                target="_blank"
                              >
                                ACC Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 text-start ps-2">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row8 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/atc/rating/8.webp' alt='Senior Controller'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                                Senior Controller (SEC)
                            </td>
                            <td className="col2 border-e border-gray-600">750</td>
                            <td className="col3 border-e border-gray-600">✓</td>
                            <td className="col4 border-e border-gray-600">✓</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="http://mediawiki.ivao.aero/index.php?title=Senior_Controller_briefing"
                                target="_blank"
                              >
                                SEC Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 text-start ps-2">
                                For members of active divisions only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row9 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/atc/rating/9.webp' alt='Senior ATC Instructor'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                                Senior ATC Instructor (SAI)
                            </td>
                            <td className="col2 border-e border-gray-600">–</td>
                            <td className="col3 text-start ps-2" colSpan="4">
                              <strong>Special rating</strong> for experienced HQ instructors and examiners. 
                              Will be issued by TD/TAD on an as-need basis.
                            </td>
                          </tr>
                          <tr className="row10">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/atc/rating/10.webp' alt='Chief ATC Instructor'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                                Chief ATC Instructor (CAI)
                            </td>
                            <td className="col2 border-e border-gray-600">–</td>
                            <td className="col3 text-start ps-2" colSpan="4">
                              <strong>Honorary rating.</strong> Is given to the Training Director and Training Assistant Director and retained after retirement.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>{" "}
                </div>}

                {documentation && <div
                  id="documentation-tab"
                  className="clearfix eael-tab-content-item inactive"
                  data-title-link="documentation-tab"
                >
                  <h2 id="controller_documentation" className={`sectionedit1 mb-3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    CONTROLLER DOCUMENTATION
                  </h2>
                  <div className="level2 ps-3">
                    <p className='mb-3'>
                      The department training proposes you the biggest
                      aeronautical documentary source of air simulation (more
                      than 200 documentations).
                    </p>
                    <p className='mb-3'>
                      These documents are adapted to the air simulation on our
                      network and they are the base of our theoretical and
                      practical examinations.
                    </p>
                    <p className='mb-3'>The documents are today downloadable in PDF format.</p>
                    <p className='mb-3'>
                      This documentation was made by HQ training department with
                      the help of some external writers, please consult the{" "}
                      <Link
                        to="#"
                        target="_blank"
                      >
                        Credits
                      </Link>
                      .
                    </p>
                    <p className='mb-3'>
                      The document can be listed by examination rank or a global
                      list can be consulted:
                    </p>
                    <ul className='list-disc ps-8 mb-3'>
                      <li className="level1">
                        <div className="li">
                          If you want to consult the{" "}
                          <strong>
                            global list of our documentation sorted in chapters
                          </strong>
                          , you shall use this{" "}
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="http://mediawiki.ivao.aero/index.php?title=Training_Documentation"
                            target="_blank"
                          >link
                          </Link>
                          .
                        </div>
                      </li>
                    </ul>
                    <ul className='list-disc ps-8 mb-3'>
                      <li className="level1">
                        <div className="li">
                          If you want to consult the{" "}
                          <strong>
                            documentation in function of examination rank
                          </strong>{" "}
                          for controller area, you can select the following links
                          below:
                        </div>
                      </li>
                    </ul>
                    <div className="table w-full sectionedit2 my-3">
                      <div className='flex justify-center content-center'>
                        <div className='w-fit border border-gray-600'>
                          <table className='text-center'>
                            <thead className=''>
                              <tr className="row0 border-b border-gray-600">
                                <th className="col0 border-e border-gray-600">Rank badge</th>
                                <td className="w-56 col1 border-e border-gray-600 text-start ps-8">
                                  <strong>Examination rank</strong>
                                </td>
                                <th className="w-80 col2">Documentation link</th>
                              </tr>
                            </thead>
                            <tbody className=''>
                              <tr className="row1 border-b border-gray-600">
                                <td className="col0 border-e border-gray-600">
                                  <img src='images/atc/rating/4.webp' alt='Trainee'/>
                                </td>
                                <td className="col1 border-e border-gray-600 text-start ps-8">Trainee</td>
                                <td className="col2 text-start ps-10">
                                  <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                    to="https://wiki.ivao.aero/en/home/training/documentation/ASx"
                                    target="_blank"
                                  >
                                    documentation for AS3 rank
                                  </Link>
                                </td>
                              </tr>
                              <tr className="row2 border-b border-gray-600">
                                <td className="col0 border-e border-gray-600">
                                  <img src='images/atc/rating/5.webp' alt='Aerodrome controller'/>
                                </td>
                                <td className="col1 border-e border-gray-600 text-start ps-8">Aerodrome controller</td>
                                <td className="col2 text-start ps-10">
                                  <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                    to="https://wiki.ivao.aero/en/home/training/documentation/ADC"
                                    target="_blank"
                                  >
                                    documentation for ADC rank
                                  </Link>
                                </td>
                              </tr>
                              <tr className="row3 border-b border-gray-600">
                                <td className="col0 border-e border-gray-600">
                                  <img src='images/atc/rating/6.webp' alt='Approach controller'/>
                                </td>
                                <td className="col1 border-e border-gray-600 text-start ps-8">Approach controller</td>
                                <td className="col2 text-start ps-10">
                                  <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                    to="https://wiki.ivao.aero/en/home/training/documentation/APC"
                                    target="_blank"
                                  >
                                    documentation for APC rank
                                  </Link>
                                </td>
                              </tr>
                              <tr className="row4 border-b border-gray-600">
                                <td className="col0 border-e border-gray-600">
                                  <img src='images/atc/rating/7.webp' alt='Area controller'/>
                                </td>
                                <td className="col1 border-e border-gray-600 text-start ps-8">Area controller</td>
                                <td className="col2 text-start ps-10">
                                  <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                    to="https://wiki.ivao.aero/en/home/training/documentation/ACC"
                                    target="_blank"
                                  >
                                    documentation for ACC rank
                                  </Link>
                                </td>
                              </tr>
                              <tr className="row5">
                                <td className="col0 border-e border-gray-600">
                                  <img src='images/atc/rating/8.webp' alt='Senior controller'/>
                                </td>
                                <td className="col1 border-e border-gray-600 text-start ps-8">Senior controller</td>
                                <td className="col2 text-start ps-10">
                                  <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                    to="https://wiki.ivao.aero/en/home/training/documentation/SEC"
                                    target="_blank"
                                  >
                                    documentation for SEC rank
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <p className='italic mb-3'>
                      <span className={`${darkMode ? txtRedColor : txtLightBlueColor}`}>Warning:</span> The documentation which apply to a certain
                      rank is also in the program of all the ranks above
                      this one.
                    </p>
                    <p className='mb-3'>
                        For Senior Controller level, you shall obtain extended knowledge by yourself. We provide some external documentation links for you:
                    </p>
                    <ul className='list-disc ps-8'>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Doc 4444 – Air Traffic Management
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Doc 8168 – Aircraft Operations, Volume I Flight
                            Procedures
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Doc 9432 – Manual of Radiotelephony
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 2: Rules of the air
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 3: Meteorological Service for International
                            Air Navigation Part I
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 3: Meteorological Service for International
                            Air Navigation Part II
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 4: Aeronautical Charts
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 6: Part I -International Commercial Air
                            Transport - Aeroplanes
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 11: Air traffic services
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 14: Aerodrome Design and Operation
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>{" "}
                </div>}

                {exams && <div
                  id="atc-exams-tab"
                  className="clearfix eael-tab-content-item inactive"
                  data-title-link="pilot-exams-tab"
                >
                  <h2 id="atc" className={`sectionedit1 mb-3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    CONTROLLER EXAMS
                  </h2>
                  <div className="level2 ps-3">
                    <p className='mb-3'>
                        Controller exam are mandatory if you want to reach Advanced IVAO ranks. You can apply for the theoretical and practical exam:
                    </p>
                    <ul className='list-disc ps-8 mb-3'>
                      <li className="level1">
                        <div className="li">
                            As soon as you have collected enough hours of practise and training at the position of requested exam
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                            As soon as you estimate that your theoretical knowledge are sufficient for the requested exam (it is mandatory to read official documentation in English for your exam)
                        </div>
                      </li>
                    </ul>
                    <p className='mb-3'>
                        The result theorical part of the exam shall be a succes before applying the practical part.
                    </p>
                    <p className='mb-3'>
                      Your exam can be asked via your{" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://ivao.aero/members/person/details.asp"
                        target="_blank"
                      >
                        IVAO Profile page
                      </Link>{" "}
                      under “My Personnal Details”.
                    </p>
                    <p>
                      Or, you can go directly to the{" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://www.ivao.aero/training/exam/default.asp"
                        target="_blank"
                      >
                        exam introduction page{" "}
                      </Link>
                      .
                    </p>
                    <p className='mb-3'>
                      Consult the{" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://doc.ivao.aero/training:procedures:exams"
                        target="_blank"
                      >
                        general examination procedures
                      </Link>{" "}
                      in order to understand the IVAO examination process
                    </p>
                    <p className='mb-3'>
                      This table shows ratings which are available for a
                      theoretical and practical examination
                    </p>
                    <div className="table sectionedit2 border border-gray-600 my-6">
                      <table className="inline text-center">
                        <thead>
                          <tr className="row0 h-10">
                            <th colSpan="7" className="col3">
                              Requirements
                            </th>
                          </tr>
                          <tr className="row1 border-y border-gray-600">
                            <th className="col0 border-e border-gray-600">Badge</th>
                            <th className="col1 border-e border-gray-600">Title</th>
                            <th className="col2 border-e border-gray-600">Minimum Hours</th>
                            <th className="col3 border-e border-gray-600">Theoretical Exam</th>
                            <th className="col4 border-e border-gray-600">Practical Exam</th>
                            <th className="col5 border-e border-gray-600">Briefing Guide</th>
                            <th className="col6 leftalign">Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="row2 border-y border-gray-600">
                            <td className="col0 border-e border-gray-600">
                              <img src='images/atc/rating/4.webp' alt='Advanced ATC Trainee'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                            Advanced ATC Trainee (AS3)
                            </td>
                            <td className="col2 border-e border-gray-600">25</td>
                            <td className="col3 border-e border-gray-600">Yes</td>
                            <td className="col4 border-e border-gray-600">No</td>
                            <td className="col5 border-e border-gray-600">
                              Refer to the IVAO documentation
                            </td>
                            <td className="col6 leftalign">
                              Theoretical exam in english
                            </td>
                          </tr>
                          <tr className="row3 border-y border-gray-600">
                            <td className="col0 border-e border-gray-600">
                              <img src='images/atc/rating/5.webp' alt='Aerodrome Controller'/>
                            </td>
                            <td className="col1 border-e border-gray-600">Aerodrome Controller (ADC)</td>
                            <td className="col2 border-e border-gray-600">50</td>
                            <td className="col3 border-e border-gray-600">Yes</td>
                            <td className="col4 border-e border-gray-600">Yes</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="https://wiki.ivao.aero/en/home/training/main/atc_briefing/ADC_Briefing"
                                target="_blank"
                              >
                                ADC Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 leftalign">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row4 border-y border-gray-600">
                            <td className="col0 border-e border-gray-600">
                              <img src='images/atc/rating/6.webp' alt='Approach Controller'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                                Approach Controller (APC)
                            </td>
                            <td className="col2 border-e border-gray-600">100</td>
                            <td className="col3 border-e border-gray-600">Yes</td>
                            <td className="col4 border-e border-gray-600">Yes</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="https://wiki.ivao.aero/en/home/training/main/atc_briefing/APC_briefing"
                                target="_blank"
                              >
                                APC Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 leftalign">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row5 border-y border-gray-600">
                            <td className="col0 border-e border-gray-600">
                              <img src='images/atc/rating/7.webp' alt='Centre Controller'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                                Centre Controller (ACC)
                            </td>
                            <td className="col2 border-e border-gray-600">200</td>
                            <td className="col3 border-e border-gray-600">Yes</td>
                            <td className="col4 border-e border-gray-600">Yes</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="https://wiki.ivao.aero/en/home/training/main/atc_briefing/ACC_briefing"
                                target="_blank"
                              >
                                ACC Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 leftalign">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row6">
                            <td className="col0 border-e border-gray-600">
                              <img src='images/atc/rating/8.webp' alt='Senior Controller'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                                Senior Controller (SEC)
                            </td>
                            <td className="col2 border-e border-gray-600">750</td>
                            <td className="col3 border-e border-gray-600">Yes</td>
                            <td className="col4 border-e border-gray-600">Dual</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="https://wiki.ivao.aero/en/home/training/main/atc_briefing/SEC_briefing"
                                target="_blank"
                              >
                                SEC Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 leftalign">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p className='mb-3'>
                        Note: Additional requirement are written in the senior controller (SEC) briefing are mandatory before applying any exam.
                    </p>
                    <p className='mb-3'>
                      Note that IVAO HQ training department had written a huge
                      documentation offer for all exams. You can consult it with
                      following this{" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://wiki.ivao.aero/en/home/training/main"
                        target="_blank"
                      >
                        link
                      </Link>
                    </p>
                  </div>{" "}
                </div>}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AtcTrainingExam;
