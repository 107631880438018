import { useContext, useEffect } from 'react';
import { NavContext, ThemeContext } from '../App';
import { txtDarkColor, txtGrayColor } from "../style/colors";

const NoPage = () => {
    const setActiveNav = useContext(NavContext).setActiveNav;
    const darkMode = useContext(ThemeContext).darkMode;

    // set global variable for active page
    useEffect(() => {
        setActiveNav('noPage');
    }, []);
    
    return(
        <div className="w-full h-96 flex flex-col justify-center content-center">
            <section className="w-full flex flex-col justify-center content-center text-center">
                <h1 className={`text-4xl font-bold ${darkMode ? txtGrayColor : txtDarkColor}`}>Page not found</h1>
            </section>
        </div>
    );

}

export default NoPage;