import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDisplay, faGraduationCap, faGlobe, faUsersBetweenLines, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { bgDarkGrayColor, txtDarkColor, txtWhiteColor, gradientWhiteColor } from "../../style/colors";

const HomeAdSection = (props) => {
    const {darkMode} = props.data;

    return(
        <section className={`w-full flex flex-col justify-center content-center text-center p-10 bg-gradient-to-t ${darkMode ? bgDarkGrayColor+" "+txtWhiteColor : gradientWhiteColor+" "+txtDarkColor}`}>
            <h1 className={`text-2xl sm:text-3xl font-bold uppercase p-5 ${darkMode ? txtWhiteColor : txtDarkColor}`}>Why Choose Us</h1>
            {/* <h3 className="text-lg sm:text-xl font-bold p-2">Other networks exist offering similar services, but none can match IVAO!</h3> */}
            <ul className="flex justify-center p-4">
                <table>
                    <tbody className="text-start">
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faDisplay} className='pt-1.5' />
                            </td>
                            <td>
                                <li className="text-xl ms-5">Best software</li>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faGraduationCap} className='pt-1.5' />
                            </td>
                            <td>
                                <li className="text-xl ms-5">Best training</li>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faGlobe} className='pt-1.5' />
                            </td>
                            <td>
                                <li className="text-xl ms-5">Global coverage</li>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faUsersBetweenLines} className='pt-1.5' />
                            </td>
                            <td>
                                <li className="text-xl ms-5">Largest community</li>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FontAwesomeIcon icon={faMoneyBill} className='pt-1.5' />
                            </td>
                            <td>
                                <li className="text-xl ms-5">Free to use</li>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ul>
        </section>
    );

}

export default HomeAdSection;