// Background colors
const bgDarkColor = "bg-gray-900";
const bgDarkGrayColor = "bg-gray-800";
const bgGrayColor700 = "bg-gray-700";
const bgGrayColor = "bg-gray-500";
const bgWhiteColor = "bg-gray-200";
const bgWhiteGrayColor = "bg-gray-300";
const bgBlueColor = "bg-blue-800";
const bgLightBlueColor = "bg-blue-300";
const bgRedColor = "bg-red-700";
const bgYellowColor = "bg-yellow-500";

// Text colors
const txtDarkColor = "text-gray-900";
const txtWhiteColor = "text-gray-200";
const txtGrayColor = "text-gray-300";
const txtDarkGrayColor = "text-gray-400";
const txtBlueColor = "text-blue-800";
const txtLightBlueColor = "text-blue-600";
const txtRedColor = "text-red-700";
const txtYellowColor = "text-yellow-500";

// Border colors
const borderWhiteColor = "border-gray-300";
const borderGrayColor = "border-gray-600";
const borderDarkColor = "border-gray-800";

//------------------------------------------------------------

// Hover Background colors
const hoverBGDarkColor = "hover:bg-gray-900";
const hoverBGDarkGrayColor = "hover:bg-gray-800";
const hoverBGGrayColor700 = "hover:bg-gray-700";
const hoverBGGrayColor = "hover:bg-gray-500";
const hoverBGWhiteColor = "hover:bg-gray-200";
const hoverBGWhiteGrayColor = "hover:bg-gray-300";
const hoverBGBlueColor = "hover:bg-blue-800";
const hoverBGLightBlueColor = "hover:bg-blue-300";
const hoverBGRedColor = "hover:bg-red-700";
const hoverBGYellowColor = "hover:bg-yellow-500";

// Hover Text colors
const hoverTxtDarkColor = "hover:text-gray-900";
const hoverTxtWhiteColor = "hover:text-gray-200";
const hoverTxtGrayColor = "hover:text-gray-300";
const hoverTxtBlueColor = "hover:text-blue-800";
const hoverTxtLightBlueColor = "hover:text-blue-600";
const hoverTxtRedColor = "hover:text-red-700";
const hoverTxtYellowColor = "hover:text-yellow-500";

// Gradient colors
const gradientDarkColor = "from-gray-800 to-gray-900";
const gradientWhiteColor = "from-gray-300 to-gray-200";

export {bgDarkColor, bgDarkGrayColor, bgGrayColor700, bgGrayColor, bgWhiteColor, bgWhiteGrayColor, bgBlueColor, bgLightBlueColor, bgRedColor, bgYellowColor,
    txtDarkColor, txtWhiteColor, txtGrayColor, txtDarkGrayColor, txtBlueColor, txtLightBlueColor, txtRedColor, txtYellowColor, borderWhiteColor, borderGrayColor, borderDarkColor,
    gradientDarkColor, gradientWhiteColor,
    hoverBGDarkColor, hoverBGDarkGrayColor, hoverBGGrayColor700, hoverBGGrayColor, hoverBGWhiteColor, hoverBGWhiteGrayColor, hoverBGBlueColor, hoverBGLightBlueColor, hoverBGRedColor, hoverBGYellowColor,
    hoverTxtDarkColor, hoverTxtWhiteColor, hoverTxtGrayColor, hoverTxtBlueColor, hoverTxtLightBlueColor, hoverTxtRedColor, hoverTxtYellowColor
}
