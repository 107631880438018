import { useContext, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { bgDarkColor, bgDarkGrayColor, bgGrayColor700, bgLightBlueColor, bgWhiteColor, bgWhiteGrayColor, borderGrayColor, hoverBGDarkGrayColor, hoverBGWhiteGrayColor, hoverTxtDarkColor, hoverTxtGrayColor, txtDarkColor, txtGrayColor, txtLightBlueColor, txtRedColor, txtWhiteColor } from '../style/colors';
import { NavContext, ThemeContext } from '../App';

const PilotTrainingExam = () => {
  const setActiveNav = useContext(NavContext).setActiveNav;
  const darkMode = useContext(ThemeContext).darkMode;

  // Menu states
  const [firstSteps, setFirstSteps] = useState(true);
  const [rating, setRating] = useState(false);
  const [documentation, setDocumentation] = useState(false);
  const [exams, setExams] = useState(false);

  // set global variable for active page
  useEffect(() => {
    setActiveNav('pilots');
  }, []);

  // Reset all menu states on click function
  function handleClick(){
    setFirstSteps(false);
    setRating(false);
    setDocumentation(false);
    setExams(false);
  }


  return (
    <div className="flex justify-center content-center pb-10">
      <div className={`w-screen sm:w-full ${darkMode ? txtGrayColor : txtDarkColor }`}>
        <div className="w-full flex flex-col sm:flex-row">
          <div className="w-full sm:min-w-48 sm:max-w-48 h-full navMenu mb-8">
            <ul className={`w-full select-none  ${darkMode ? bgDarkGrayColor : bgWhiteGrayColor}`}>
              <li className={`pilotsMenuItem cursor-pointer h-10 lg:h-12 font-bold text-center flex flex-col justify-center border-y border-x ${borderGrayColor} ${darkMode ? hoverBGWhiteGrayColor +" "+hoverTxtDarkColor : hoverBGDarkGrayColor+" "+hoverTxtGrayColor} ${firstSteps ? (darkMode ? bgWhiteGrayColor+" "+txtDarkColor : bgDarkGrayColor+" "+txtGrayColor) : (darkMode ? bgDarkColor : bgWhiteColor)}`} onClick={() => {handleClick(); setFirstSteps(true)}}>PILOT FIRST STEPS</li>
              <li className={`pilotsMenuItem cursor-pointer h-10 lg:h-12 font-bold text-center flex flex-col justify-center border-b border-x ${borderGrayColor} ${darkMode ? hoverBGWhiteGrayColor +" "+hoverTxtDarkColor : hoverBGDarkGrayColor+" "+hoverTxtGrayColor} ${rating ? (darkMode ? bgWhiteGrayColor+" "+txtDarkColor : bgDarkGrayColor+" "+txtGrayColor) : (darkMode ? bgDarkColor : bgWhiteColor)}`} onClick={() => {handleClick(); setRating(true)}}>PILOT RATINGS</li>
              <li className={`pilotsMenuItem cursor-pointer h-10 lg:h-12 font-bold text-center flex flex-col justify-center border-b border-x ${borderGrayColor} ${darkMode ? hoverBGWhiteGrayColor +" "+hoverTxtDarkColor : hoverBGDarkGrayColor+" "+hoverTxtGrayColor} ${documentation ? (darkMode ? bgWhiteGrayColor+" "+txtDarkColor : bgDarkGrayColor+" "+txtGrayColor) : (darkMode ? bgDarkColor : bgWhiteColor)}`} onClick={() => {handleClick(); setDocumentation(true)}}>DOCUMENTATION</li>
              <li className={`pilotsMenuItem cursor-pointer h-10 lg:h-12 font-bold text-center flex flex-col justify-center border-b border-x ${borderGrayColor} ${darkMode ? hoverBGWhiteGrayColor +" "+hoverTxtDarkColor : hoverBGDarkGrayColor+" "+hoverTxtGrayColor} ${exams ? (darkMode ? bgWhiteGrayColor+" "+txtDarkColor : bgDarkGrayColor+" "+txtGrayColor) : (darkMode ? bgDarkColor : bgWhiteColor)}`} onClick={() => {handleClick(); setExams(true)}}>PILOT EXAMS</li>
            </ul>
          </div>
          <div className="w-full h-full">
            <section className="w-full flex justify-center content-center p-6">
              <div className="eael-tabs-content text-xs sm:text-sm md:text-base">
                {firstSteps && <div>
                  <Link to="https://wiki.ivao.aero/en/home/devops/manuals/altitude-first-step" target='_blank'>
                    <h2 className={`text-xl lg:text-2xl font-bold mb-3 hover:underline ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                      PILOT FIRST STEPS
                    </h2>
                  </Link>
                  <div className='mb-8 ps-3'>
                    <p className='mb-3'>
                      This tutorial will show you the steps required to join
                      IVAO as a virtual pilot. It is meant to give you a short
                      overview about the tools used, and some very basic
                      instructions how to “behave” in this new environment.
                    </p>
                    <p className='mb-3'>
                      If you have not yet done so, you will need to{" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://ivao.aero/members/person/register.htm"
                        target="_blank"
                      >
                        join IVAO
                      </Link>{" "}
                      to create your personal user account.
                    </p>
                    <p className="text-info mb-3">
                      Note that this is required only once, and valid for both
                      ATC and pilot career!
                    </p>
                    <p>More specifically, we will show you how to…</p>
                    <ul className='list-disc ps-8'>
                      <li className="level1">
                        <div className="li">
                          Download, install and set up the required software
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          Connect to the IVAO Network (“Altitude”)
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">Submit a (basic) flight plan</div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          Interact with Air Traffic Control (ATC), or how to act
                          in absence of ATC
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          Obtain further information and training
                        </div>
                      </li>
                    </ul>
                  </div>
                  <h3 id="download_ivap" className={`sectionedit2 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    Download Altitude
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                      The first thing you need to do is download our pilot
                      client, Altitude, which will allow you to connect to the
                      network. This can be downloaded from {" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://ivao.aero/softdev/software.asp"
                        target="_blank"
                      >
                        IVAO Software
                      </Link>
                      .
                    </p>
                    <p className='mb-3'>
                      Once you have downloaded the program, you should run the
                      executable file and install the program.
                    </p>
                  </div>
                  <h3 id="mtl_multiplayer_traffic_library" className={`sectionedit3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    MTL (MULTIPLAYER TRAFFIC LIBRARY)
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                      MTL (Multiplayer Traffic Library) will enable you to “see”
                      other aircraft around you by installing hundreds of
                      aircraft and their liveries into your flight simulator.
                    </p>
                    <p className='mb-3'>
                      The MTL Installer is NOT included in the Altitude download/installation.
                      You will be redirected to the <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`} to="https://mtl.ivao.aero/installer" target="_blank">MTL Website</Link> to download the installer.
                    </p>
                    <p className="text-info">
                      Execute your MTL installer periodically to download the
                      latest MTL updates.
                    </p>
                  </div>
                  <h3 id="first_connection" className={`sectionedit5 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    FIRST CONNECTION
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                    On the IVAO Network, it is mandatory to file a flight plan for every flight. Before or after connecting to IVAO Worldservers, you should file a flight plan.
                    </p>
                    <p className='mb-3'>Load Flight simulator and create a flight. Once your simulator is running and your aircraft is loaded, open IVAO Pilot Client: Altitude.</p>
                    <p className='mb-3'>
                      Altitude consists of 2 parts, Pilot Core and Pilot UI. For MSFS, Pilot Core can be launched manually using the shortcut created on the Desktop named "IVAO Pilot Core" but it should automatically launch when you launch the Pilot UI. Shortcut is there just in case.
                    </p>
                    <p className='font-bold mb-2'>
                      Some important things to do before connecting to the
                      network:
                    </p>
                    <ul className='list-disc ps-8 mb-3'>
                      <li className="level1">
                        <div className="li">
                          <span className="text-danger">
                            Make sure that when you are connected, your aircraft
                            is parked at a gate. Do not connect on the runway or
                            on a taxiway.
                          </span>{" "}
                          When choosing your airfield within the Flight Sim
                          menus, it automatically places you on the active
                          runway so ensure that you have selected a gate in the
                          appropriate drop down menu.
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          Do not use the slew option in flight sim to slew
                          around the airport once you are connected. If you have
                          to change gates just after you connected, use the
                          options within flight sim to load your aircraft at a
                          new gate.
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          If connecting with an airline callsign, you must use
                          the airlines ICAO code followed by the flight number,
                          i.e. BAW123 not BA123.
                        </div>
                      </li>
                    </ul>
                    <p className='mb-3'>
                      To connect, press the “OFFLINE” button. You will then be
                      presented with a new window.
                    </p>
                    <p className='mb-2'>Enter the details:</p>
                    <ul className='list-disc ps-8'>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>Callsign:</strong> The callsign of your
                          aircraft, this could be your aircraft registration or
                          flight number for example.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>VID:</strong> Your IVAO VID that was assigned
                          to you when you joined.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>Password:</strong> The Website password you were
                          assigned when you joined IVAO.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>Real Name:</strong> Your full name that you registered with IVAO.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>Server:</strong> The server you are connecting
                          to. For best performance, use the one auto selected.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>Port:</strong> Leave this box as it is!
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>Aircraft Type:</strong> The type of aircraft
                          you are flying.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          <strong>MTL Model:</strong> The aircraft and livery
                          you want other people to see you as.
                        </div>
                      </li>
                      <li className="level1 mb-2">
                        <div className="li">
                          If you can transmit and receive on voice, select that
                          option. If you can receive voice but cant transmit
                          voice, select that option. If you are text only,
                          select no voice.
                        </div>
                      </li>
                    </ul>
                    <p className="text-danger mb-3">
                      Once again, make sure your aircraft is parked at a gate
                      before connecting!
                    </p>
                    <p className='mb-3'>
                      When all of the above details are correct, press the
                      “Connect” button.
                    </p>
                    <p className='mb-3'>
                      When you have successfully connected, the sign “OFFLINE” will change to “ONLINE”, and you will receive a welcome message in the IVAO Pilot Client dialog box.
                    </p>
                  </div>
                  <h3 id="flight_planning" className={`sectionedit6 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    FLIGHT PLANNING
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                      To file a flight plan, open <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`} to="https://fpl.ivao.aero/home" target='_blank'>IVAO Flight Plan System</Link> . Most fields will be unknown to you, for your first flight it is sufficient to fill flight rules, departure aerodrome, route and destination aerodrome.
                    </p>
                    <p className={` rounded p-2 w-fit mb-3 ${darkMode ? bgGrayColor700 : bgLightBlueColor}`}>
                      Learn how to use the Flight Plan System of IVAO in detail! <Link className={`${darkMode ? txtRedColor : txtLightBlueColor} underline`} to="https://wiki.ivao.aero/en/home/devops/websites/flightplansystem" target='_blank'>Flight Plan System</Link>
                    </p>
                    <p className='mb-3'>
                      Some airspaces are very compact, complicated and get very congested. We recommend that your first flight is a local flight at a quiet airport (and not the big international field of your capital!) in order to start with a simple setup. The easiest way is a local flight according to Visual Flight Rules, that is doing traffic patterns around the airfield and flying in the vicinity of the airport.
                    </p>
                    <p className='mb-3'>
                      For such a flight, fill in the flight plan as follows and
                      refer to the picture for the location of the mentioned
                      items:
                    </p>
                    <div className='w-full flex justify-center content-center my-6'>
                      <img className='w-9/12' src='images/pilot/9.webp' alt="flight plan example"></img>
                    </div>
                    <ul className='list-disc ps-8 mb-3'>
                    <li className="level1 pb-2">
                        <div className="li">
                          <strong>Field 7, Aircraft identification.</strong>
                        </div>
                      </li>
                      <li className="level1 pb-2">
                        <div className="li">
                          <strong>Field 8, Flight Rules:</strong> “V” for VFR
                          flight.
                        </div>
                      </li>
                      <li className="level1 pb-2">
                        <div className="li">
                          <strong>Field 13, Departure Aerodrome:</strong> Enter
                          the ICAO-Code of your departure.
                        </div>
                      </li>
                      <li className="level1 pb-2">
                        <div className="li">
                          <strong>Field 15, Route:</strong> “LOCAL” or “DCT” for
                          your local flight.
                        </div>
                      </li>
                      <li className="level1 pb-2">
                        <div className="li">
                          <strong>Field 16, Destination Aerodrome:</strong>{" "}
                          Enter the ICAO-Code of your destination, in this case
                          the same as departure for your local flight.
                        </div>
                      </li>
                    </ul>
                    <p className='mb-3'>
                      Later on, you might want to fly a route from A to B, employing Instrument Flight Rules. To find your way, you should follow the “motorways” in the sky, so called "airways". There are many different sources to find these routes.
                    </p>
                    <p>
                      You will need to have enroute and aerodrome charts at your
                      disposal to follow those routes. Controllers use these
                      charts as well and will expect you to have them. Charts
                      for your flight can be found on the division's website of
                      the country you are flying at (see{" "}
                      <Link
                        to="https://ivao.aero/Content/About/Divisions.aspx"
                        target="_blank"
                      >
                        active divisions list
                      </Link>
                      ). Another good method is to query your favourite internet
                      search engine for the words “[ICAO code] charts”, replace
                      “[ICAO code]” with the code of your departure/destination
                      airfield.
                    </p>
                  </div>
                  <h3 id="interaction_with_atc" className={`sectionedit7 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    INTERACTION WITH ATC
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                      The purpose of IVAO is to fly online with virtual Air
                      Traffic Controllers guiding you through the skies. You
                      should therefore have an understanding of how to
                      communicate with controllers. The United Kingdom Division
                      offers an example of how to{" "}
                      <Link
                        to="http://ivao.co.uk/pilot/guidelines"
                        target="_blank"
                      >
                        communicate for a VFR and IFR flight
                      </Link>
                      .
                    </p>
                    <p className='mb-3'>
                      When connected and ready to start your flight, you have to
                      make sure if there is an online ATC station in your
                      airport or area. To do so, type in your IvAp .ATC and a
                      list will appear with all active controllers in your area,
                      including their respective frequencies. See the example
                      below:
                    </p>
                    <p className='mb-3'>
                      Tune the proper frequency of the controller in your radio
                      shack. From now you can communicate with the controller.
                    </p>
                    <p className='mb-3'>
                      If you have selected to use voice for your communications,
                      when inserted the appropriate frequency, you will also
                      automatically join the TeamSpeak channel of the
                      controller.
                    </p>
                    <p className='mb-3'>
                      Before contacting the controller you should retrieve his
                      ATIS. Knowing the information of the controller's ATIS
                      will help you communicating and flying in his airspace. To
                      retrieve the ATIS, you can follow the steps below:
                    </p>
                    <p className='mb-3'>
                      The primary form of communication with ATC is voice.
                      However, text communication may be used. When
                      communicating via voice, there are some important things
                      you should remember: •When a controller or another pilot
                      transmits on the frequency, wait until the other station
                      has acknowledged and replied to the transmission before
                      you transmit something. •When you first connect to a new
                      frequency, wait for a few seconds before transmitting. You
                      may inadvertently interrupt a conversation.
                    </p>
                    <p className="text-info mb-3">
                      If you have trouble tuning to a controller's frequency
                      using your airplane radios, you can type in your IvAp .c
                      [the proper frequency] and hit enter, example: .c 132.975.
                    </p>
                  </div>
                  <h3 id="unicom" className={`sectionedit8 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    UNICOM
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p className='mb-3'>
                      If there is no ATC online in the area where you are
                      flying, you should tune to the Unicom frequency of 122.800
                      (a text only frequency) and broadcast your intentions.
                      When flying on Unicom, keep a good look out for other
                      traffic that may be operating in your area.
                    </p>
                    <p className='mb-3'>
                      The “UNICOM” frequency here on IVAO is a text-only
                      frequency. This means that all your transmissions in this
                      frequency are done only by text. Hence when you select
                      this frequency, you will not join automatically a
                      TeamSpeak channel.
                    </p>
                    <p className='mb-3'>
                      Unicom is not to be used as a chat channel. It should be
                      used for operational messages only. You should use the
                      private message feature within IvAp to chat to individual
                      people.
                    </p>
                    <p className='mb-3'>
                      When you first load flight sim, the default frequency is
                      122.950. If there is no ATC at your departure aerodrome,
                      please remember to first of all tune to Unicom 122.800 or
                      you may transmit your intentions on the wrong frequency.
                    </p>
                    <p className="text-warning mb-3">
                      The UNICOM frequency is not meant to chat with other
                      pilots. If you wish to chat with another pilot or ATC,
                      type in your IVAP .chat [airplane or ATC callsign], hit
                      enter and a chat window opens.
                    </p>
                  </div>
                  <h3 id="further_information" className={`sectionedit9 mb-3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    FURTHER INFORMATION
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p>
                      You may find our{" "}
                      <Link
                        to="http://forum.ivao.aero/"
                        target="_blank"
                      >
                        IVAO Forum
                      </Link>{" "}
                      incredibly helpful. A local division forum is also
                      available, select it from the drop-down menu at the top.
                      You can use it to ask any questions or post about any
                      problems you are having. There are lots of people willing
                      and able to help you make the most out of your online
                      flying experience.
                    </p>
                    <p>
                      If you cannot find what you are looking in the forum or a
                      division site, you can use your favorite internet search
                      engine to find what you want.
                    </p>
                  </div>
                  <h3 id="training" className={`sectionedit10 mb-3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    TRAINING
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p>
                      If you would like to extend your understanding with
                      regards to online flying, you may find it beneficial to
                      request a training session from your local division's
                      training department. This can be done via the training
                      section of your division website, which is linked to{" "}
                      <strong>
                        <Link
                          to="http://xx.ivao.aero/"
                          target="_parent"
                        >
                          http://xx.ivao.aero/
                        </Link>
                      </strong>{" "}
                      (replace “xx” with the two-letter code of your division).
                      If you are unsure about the correct code, visit your{" "}
                      <Link
                        to="https://ivao.aero/members/person/details.asp"
                        target="_blank"
                      >
                        personal profile
                      </Link>{" "}
                      page – it is listed there.
                    </p>
                  </div>
                  <h3 id="and_finally" className={`sectionedit11 mb-3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    AND FINALLY…
                  </h3>
                  <div className="level3 mb-8 ps-3">
                    <p>
                      Have fun! This is an incredibly addictive hobby. As soon
                      as you start flying online, you will never go back to
                      offline flying and will wonder why you didn't do it
                      sooner!
                    </p>
                  </div>{" "}
                  {/* Load Images to improve performance */}
                  <div className="hidden static bottom-0 flex justify-end">
                        <img src="images/pilot/rating/2.webp" className="w-1" alt="imgload"></img>
                        <img src="images/pilot/rating/3.webp" className="w-1" alt="imgload"></img>
                        <img src="images/pilot/rating/4.webp" className="w-1" alt="imgload"></img>
                        <img src="images/pilot/rating/5.webp" className="w-1" alt="imgload"></img>
                        <img src="images/pilot/rating/6.webp" className="w-1" alt="imgload"></img>
                        <img src="images/pilot/rating/7.webp" className="w-1" alt="imgload"></img>
                        <img src="images/pilot/rating/8.webp" className="w-1" alt="imgload"></img>
                        <img src="images/pilot/rating/9.webp" className="w-1" alt="imgload"></img>
                        <img src="images/pilot/rating/10.webp" className="w-1" alt="imgload"></img>
                  </div>
                </div>}

                {rating && <div
                  id="pilot-ratings-tab"
                  className="clearfix eael-tab-content-item inactive"
                  data-title-link="pilot-ratings-tab"
                >
                  <h2 id="pilot_ratings" className={`sectionedit1 mb-3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    PILOT RATINGS
                  </h2>
                  <div className="level2 mb-8 ps-3">
                    <p className='mb-3'>
                      Ratings reflect the amount of practice that you have
                      completed with IVAO, based on time spent on controls and
                      number of successfully completed exams.
                    </p>
                    <p className='mb-3'>
                      As soon as you have collected enough hours of practise and
                      training, you can apply for the theoretical and practical
                      exam of the next higher rating. The ratings are done in
                      the sequence shown, you cannot jump over an intermediate
                      rating.
                    </p>
                    <p className='mb-3'>
                      The IVAO Training Department establishes minimum standards
                      for each rating level. Your local division may require
                      additional higher standards.
                    </p>
                    <p className='mb-3'>
                      This table shows which ratings are available at IVAO, and
                      which requirements you will have to fulfill to attain a
                      rating. Your current rating is shown in your{" "}
                      <Link
                        to="https://ivao.aero/members/person/details.asp"
                        target="_blank"
                      >
                        IVAO Profile
                      </Link>{" "}
                      under “Pilot Info”.
                    </p>
                    <div className="table sectionedit2 border rounded border-gray-700">
                      <table className="inline text-center">
                        <thead className=''>
                          <tr className="h-12 w-full row0">
                            <th colSpan='7'>
                              <h2 className='text-center'>Requirements</h2>
                            </th>
                          </tr>
                          <tr className="row1 border-y border-gray-600">
                            <th className="col0 border-x border-gray-600">Badge</th>
                            <th className="col1 border-e border-gray-600">Title</th>
                            <th className="col2 border-e border-gray-600">Minimum Hours</th>
                            <th className="col3 border-e border-gray-600">Theoretical Exam</th>
                            <th className="col4 border-e border-gray-600">Practical Exam</th>
                            <th className="col5 border-e border-gray-600">Briefing Guide</th>
                            <th className="col6">Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="row2 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/pilot/rating/2.webp' alt='Basic Flight Student'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                              Basic Flight Student (FS1)
                            </td>
                            <td className="col2 border-e border-gray-600">0</td>
                            <td className="col3 border-e border-gray-600">–</td>
                            <td className="col4 border-e border-gray-600">–</td>
                            <td className="col5 border-e border-gray-600">Not required</td>
                            <td className="col6 text-start ps-2">
                              Rating given when registering with IVAO.
                            </td>
                          </tr>
                          <tr className="row3 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/pilot/rating/3.webp' alt='Flight Student'/>
                            </td>
                            <td className="col1 border-e border-gray-600">Flight Student (FS2)</td>
                            <td className="col2 border-e border-gray-600">10</td>
                            <td className="col3 border-e border-gray-600">–</td>
                            <td className="col4 border-e border-gray-600">–</td>
                            <td className="col5 border-e border-gray-600">Not required</td>
                            <td className="col6 text-start ps-2">
                              Rating automatically achieved after completing the
                              minimum hours.
                            </td>
                          </tr>
                          <tr className="row4 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/pilot/rating/4.webp' alt='Advanced Flight Student'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                              Advanced Flight Student (FS3)
                            </td>
                            <td className="col2 border-e border-gray-600">25</td>
                            <td className="col3 border-e border-gray-600">✓</td>
                            <td className="col4 border-e border-gray-600">–</td>
                            <td className="col5 border-e border-gray-600">
                              Refer to the IVAO documentation
                            </td>
                            <td className="col6 text-start ps-2">
                              Theoretical exam in english
                            </td>
                          </tr>
                          <tr className="row5 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/pilot/rating/5.webp' alt='Private Pilot'/>
                            </td>
                            <td className="col1 border-e border-gray-600">Private Pilot (PP)</td>
                            <td className="col2 border-e border-gray-600">50</td>
                            <td className="col3 border-e border-gray-600">✓</td>
                            <td className="col4 border-e border-gray-600">✓</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="http://mediawiki.ivao.aero/index.php?title=Private_Pilot_briefing"
                                target="_blank"
                              >
                                PP Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 text-start ps-2">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row6 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/pilot/rating/6.webp' alt='Senior Private Pilot'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                              Senior Private Pilot (SPP)
                            </td>
                            <td className="col2 border-e border-gray-600">100</td>
                            <td className="col3 border-e border-gray-600">✓</td>
                            <td className="col4 border-e border-gray-600">✓</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="http://mediawiki.ivao.aero/index.php?title=Senior_Private_Pilot_briefing"
                                target="_blank"
                              >
                                SPP Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 text-start ps-2">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row7 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/pilot/rating/7.webp' alt='Commercial Pilot'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                              Commercial Pilot (CP)
                            </td>
                            <td className="col2 border-e border-gray-600">200</td>
                            <td className="col3 border-e border-gray-600">✓</td>
                            <td className="col4 border-e border-gray-600">✓</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="http://mediawiki.ivao.aero/index.php?title=Commercial_Pilot_briefing"
                                target="_blank"
                              >
                                CP Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 text-start ps-2">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row8 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/pilot/rating/8.webp' alt='Airline Transport Pilot'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                              Airline Transport Pilot (ATP)
                            </td>
                            <td className="col2 border-e border-gray-600">750</td>
                            <td className="col3 border-e border-gray-600">✓</td>
                            <td className="col4 border-e border-gray-600">✓</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="http://mediawiki.ivao.aero/index.php?title=Airline_Transport_Pilot_briefing"
                                target="_blank"
                              >
                                ATP Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 text-start ps-2">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row9 border-b border-gray-600">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/pilot/rating/9.webp' alt='Senior Flight Instructor'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                              Senior Flight Instructor (SFI)
                            </td>
                            <td className="col2 border-e border-gray-600">–</td>
                            <td className="col3 text-start ps-2" colSpan="4">
                              <strong>Special rating</strong> for experienced HQ
                              instructors and examiners. Will be issued by
                              TD/TAD on an as-need basis.
                            </td>
                          </tr>
                          <tr className="row10">
                            <td className="col0 border-x border-gray-600">
                              <img src='images/pilot/rating/10.webp' alt='Chief Flight Instructor'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                              Chief Flight Instructor (CFI)
                            </td>
                            <td className="col2 border-e border-gray-600">–</td>
                            <td className="col3 text-start ps-2" colSpan="4">
                              <strong>Honorary rating.</strong> Is given to the
                              Training Director and Training Assistant Director
                              and retained after retirement.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>{" "}
                </div>}

                {documentation && <div
                  id="documentation-tab"
                  className="clearfix eael-tab-content-item inactive"
                  data-title-link="documentation-tab"
                >
                  <h2 id="pilot_documentation" className={`sectionedit1 mb-3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    PILOT DOCUMENTATION
                  </h2>
                  <div className="level2 ps-3">
                    <p className='mb-3'>
                      The department training proposes you the biggest
                      aeronautical documentary source of air simulation (more
                      than 200 documentations).
                    </p>
                    <p className='mb-3'>
                      These documents are adapted to the air simulation on our
                      network and they are the base of our theoretical and
                      practical examinations.
                    </p>
                    <p className='mb-3'>The documents are today downloadable in PDF format.</p>
                    <p className='mb-3'>
                      This documentation was made by HQ training department with
                      the help of some external writers, please consult the{" "}
                      <Link
                        to="#"
                        target="_blank"
                      >
                        Credits
                      </Link>
                      .
                    </p>
                    <p className='mb-3'>
                      The document can be listed by examination rank or a global
                      list can be consulted:
                    </p>
                    <ul className='list-disc ps-8 mb-3'>
                      <li className="level1">
                        <div className="li">
                          If you want to consult the{" "}
                          <strong>
                            global list of our documentation sorted in chapters
                          </strong>
                          , you shall use this{" "}
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="http://mediawiki.ivao.aero/index.php?title=Training_Documentation"
                            target="_blank"
                          >link
                          </Link>
                          .
                        </div>
                      </li>
                    </ul>
                    <ul className='list-disc ps-8 mb-3'>
                      <li className="level1">
                        <div className="li">
                          If you want to consult the{" "}
                          <strong>
                            documentation in function of examination rank
                          </strong>{" "}
                          for pilot area, you can select the following links
                          below:
                        </div>
                      </li>
                    </ul>
                    <div className="table w-full sectionedit2 my-3">
                      <div className='flex justify-center content-center'>
                        <div className='w-fit border border-gray-600'>
                          <table className='text-center'>
                            <thead className=''>
                              <tr className="row0 border-b border-gray-600">
                                <th className="col0 border-e border-gray-600">Rank badge</th>
                                <td className="w-56 col1 border-e border-gray-600 text-start ps-8">
                                  <strong>Examination rank</strong>
                                </td>
                                <th className="w-80 col2">Documentation link</th>
                              </tr>
                            </thead>
                            <tbody className=''>
                              <tr className="row1 border-b border-gray-600">
                                <td className="col0 border-e border-gray-600">
                                  <img src='images/pilot/rating/4.webp' alt='Advanced Flight Student'/>
                                </td>
                                <td className="col1 border-e border-gray-600 text-start ps-8">Trainee</td>
                                <td className="col2 text-start ps-10">
                                  <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                    to="https://wiki.ivao.aero/en/home/training/documentation/FSx"
                                    target="_blank"
                                  >
                                    documentation for FS3 rank
                                  </Link>
                                </td>
                              </tr>
                              <tr className="row2 border-b border-gray-600">
                                <td className="col0 border-e border-gray-600">
                                  <img src='images/pilot/rating/5.webp' alt='Private Pilot'/>
                                </td>
                                <td className="col1 border-e border-gray-600 text-start ps-8">Private Pilot</td>
                                <td className="col2 text-start ps-10">
                                  <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                    to="https://wiki.ivao.aero/en/home/training/documentation/PP"
                                    target="_blank"
                                  >
                                    documentation for PP rank
                                  </Link>
                                </td>
                              </tr>
                              <tr className="row3 border-b border-gray-600">
                                <td className="col0 border-e border-gray-600">
                                  <img src='images/pilot/rating/6.webp' alt='Senior Private Pilot'/>
                                </td>
                                <td className="col1 border-e border-gray-600 text-start ps-8">Senior Private Pilot</td>
                                <td className="col2 text-start ps-10">
                                  <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                    to="https://wiki.ivao.aero/en/home/training/documentation/SPP"
                                    target="_blank"
                                  >
                                    documentation for SPP rank
                                  </Link>
                                </td>
                              </tr>
                              <tr className="row4 border-b border-gray-600">
                                <td className="col0 border-e border-gray-600">
                                  <img src='images/pilot/rating/7.webp' alt='Commercial Pilot'/>
                                </td>
                                <td className="col1 border-e border-gray-600 text-start ps-8">Commercial Pilot</td>
                                <td className="col2 text-start ps-10">
                                  <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                    to="https://wiki.ivao.aero/en/home/training/documentation/CP"
                                    target="_blank"
                                  >
                                    documentation for CP rank
                                  </Link>
                                </td>
                              </tr>
                              <tr className="row5">
                                <td className="col0 border-e border-gray-600">
                                  <img src='images/pilot/rating/8.webp' alt='Airline Transport Pilot'/>
                                </td>
                                <td className="col1 border-e border-gray-600 text-start ps-8">Air Transport Pilot</td>
                                <td className="col2 text-start ps-10">
                                  <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                    to="https://wiki.ivao.aero/en/home/training/documentation/ATP"
                                    target="_blank"
                                  >
                                    documentation for ATP rank
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <p className='italic mb-3'>
                      <span className={`${darkMode ? txtRedColor : txtLightBlueColor}`}>Warning:</span> The documentation which apply to a certain
                      rank is also in the program of all the ranks above
                      this one.
                    </p>
                    <p className='mb-3'>
                      For Air Transport Pilot level, you shall obtain extended
                      knowledge by yourself. We provide some external
                      documentation links for you:
                    </p>
                    <ul className='list-disc ps-8'>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Doc 4444 – Air Traffic Management
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Doc 8168 – Aircraft Operations, Volume I Flight
                            Procedures
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Doc 9432 – Manual of Radiotelephony
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 2: Rules of the air
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 3: Meteorological Service for International
                            Air Navigation Part I
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 3: Meteorological Service for International
                            Air Navigation Part II
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 4: Aeronautical Charts
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 6: Part I -International Commercial Air
                            Transport - Aeroplanes
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 11: Air traffic services
                          </Link>
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                            to="https://aviation-is.better-than.tv/icaodocs/"
                            target="_blank"
                          >
                            Annex 14: Aerodrome Design and Operation
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>{" "}
                </div>}

                {exams && <div
                  id="pilot-exams-tab"
                  className="clearfix eael-tab-content-item inactive"
                  data-title-link="pilot-exams-tab"
                >
                  <h2 id="pilot_exams" className={`sectionedit1 mb-3 text-xl lg:text-2xl font-bold mb-3 ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                    PILOT EXAMS
                  </h2>
                  <div className="level2 ps-3">
                    <p className='mb-3'>
                      Pilot exam are mandatory if you want to reach Advanced
                      IVAO ranks. You can apply for the theoretical and
                      practical exam:
                    </p>
                    <ul className='list-disc ps-8 mb-3'>
                      <li className="level1">
                        <div className="li">
                          As soon as you have collected enough hours of practise
                          and training with an aircraft compatible with the
                          requested exam (see briefing guide)
                        </div>
                      </li>
                      <li className="level1">
                        <div className="li">
                          As soon as you estimate that your theoretical
                          knowledge are sufficient for the requested exam (it is
                          mandatory to read official documentation in English
                          for your exam)
                        </div>
                      </li>
                    </ul>
                    <p className='mb-3'>
                      The result theorical part of the exam shall be a succes
                      before applying the practical part.
                    </p>
                    <p className='mb-3'>
                      Your exam can be asked via your{" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://ivao.aero/members/person/details.asp"
                        target="_blank"
                      >
                        IVAO Profile page
                      </Link>{" "}
                      under “My Personnal Details”.
                    </p>
                    <p>
                      Or, you can go directly to the{" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://www.ivao.aero/training/exam/default.asp"
                        target="_blank"
                      >
                        exam introduction page{" "}
                      </Link>
                      .
                    </p>
                    <p className='mb-3'>
                      Consult the{" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://doc.ivao.aero/training:procedures:exams"
                        target="_blank"
                      >
                        general examination procedures
                      </Link>{" "}
                      in order to understand the IVAO examination process
                    </p>
                    <p className='mb-3'>
                      This table shows ratings which are available for a
                      theoretical and practical examination
                    </p>
                    <div className="table sectionedit2 border border-gray-600 my-6">
                      <table className="inline text-center">
                        <thead>
                          <tr className="row0 h-10">
                            <th colSpan="7" className="col3">
                              Requirements
                            </th>
                          </tr>
                          <tr className="row1 border-y border-gray-600">
                            <th className="col0 border-e border-gray-600">Badge</th>
                            <th className="col1 border-e border-gray-600">Title</th>
                            <th className="col2 border-e border-gray-600">Minimum Hours</th>
                            <th className="col3 border-e border-gray-600">Theoretical Exam</th>
                            <th className="col4 border-e border-gray-600">Practical Exam</th>
                            <th className="col5 border-e border-gray-600">Briefing Guide</th>
                            <th className="col6 leftalign">Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="row2 border-y border-gray-600">
                            <td className="col0 border-e border-gray-600">
                              <img src='images/pilot/rating/4.webp' alt='Advanced Flight Student'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                              Advanced Flight Student (FS3)
                            </td>
                            <td className="col2 border-e border-gray-600">25</td>
                            <td className="col3 border-e border-gray-600">Yes</td>
                            <td className="col4 border-e border-gray-600">No</td>
                            <td className="col5 border-e border-gray-600">
                              Refer to the IVAO documentation
                            </td>
                            <td className="col6 leftalign">
                              Theoretical exam in english
                            </td>
                          </tr>
                          <tr className="row3 border-y border-gray-600">
                            <td className="col0 border-e border-gray-600">
                              <img src='images/pilot/rating/5.webp' alt='Private Pilot'/>
                            </td>
                            <td className="col1 border-e border-gray-600">Private Pilot (PP)</td>
                            <td className="col2 border-e border-gray-600">50</td>
                            <td className="col3 border-e border-gray-600">Yes</td>
                            <td className="col4 border-e border-gray-600">Yes</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="https://wiki.ivao.aero/en/home/training/main/pilot_briefing/PP_briefing"
                                target="_blank"
                              >
                                PP Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 leftalign">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row4 border-y border-gray-600">
                            <td className="col0 border-e border-gray-600">
                              <img src='images/pilot/rating/6.webp' alt='Senior Private Pilot'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                              Senior Private Pilot (SPP)
                            </td>
                            <td className="col2 border-e border-gray-600">100</td>
                            <td className="col3 border-e border-gray-600">Yes</td>
                            <td className="col4 border-e border-gray-600">Yes</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="https://wiki.ivao.aero/en/home/training/main/pilot_briefing/SPP_Briefing"
                                target="_blank"
                              >
                                SPP Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 leftalign">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row5 border-y border-gray-600">
                            <td className="col0 border-e border-gray-600">
                              <img src='images/pilot/rating/7.webp' alt='Commercial Pilot'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                              Commercial Pilot (CP)
                            </td>
                            <td className="col2 border-e border-gray-600">200</td>
                            <td className="col3 border-e border-gray-600">Yes</td>
                            <td className="col4 border-e border-gray-600">Yes</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="https://wiki.ivao.aero/en/home/training/main/pilot_briefing/CP_Briefing"
                                target="_blank"
                              >
                                CP Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 leftalign">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                          <tr className="row6">
                            <td className="col0 border-e border-gray-600">
                              <img src='images/pilot/rating/8.webp' alt='Airline Transport Pilot'/>
                            </td>
                            <td className="col1 border-e border-gray-600">
                              Airline Transport Pilot (ATP)
                            </td>
                            <td className="col2 border-e border-gray-600">750</td>
                            <td className="col3 border-e border-gray-600">Yes</td>
                            <td className="col4 border-e border-gray-600">Dual</td>
                            <td className="col5 border-e border-gray-600">
                              <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                                to="https://wiki.ivao.aero/en/home/training/main/pilot_briefing/ATP_Briefing"
                                target="_blank"
                              >
                                ATP Briefing Guide
                              </Link>
                            </td>
                            <td className="col6 leftalign">
                              For members of active Divs only (R&R 4.6.2.1)
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p className='mb-3'>
                      Note: Additional requirement are written in the Air
                      Transport Pilot (ATP) briefing are mandatory before
                      applying any exam.
                    </p>
                    <p className='mb-3'>
                      Note that IVAO HQ training department had written a huge
                      documentation offer for all exams. You can consult it with
                      following this{" "}
                      <Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`}
                        to="https://wiki.ivao.aero/en/home/training/main"
                        target="_blank"
                      >
                        link
                      </Link>
                    </p>
                  </div>{" "}
                </div>}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PilotTrainingExam;
