import { Link } from "react-router-dom";
import { useContext, useEffect } from 'react';
import { NavContext, ThemeContext } from '../App';
import { handleHover, handleHoverLeave } from "../functions/cardAnimation";
import { bgBlueColor, bgGrayColor, bgRedColor, txtDarkColor, txtGrayColor, txtWhiteColor } from "../style/colors";

const PilotSoftware = () => {
    const setActiveNav = useContext(NavContext).setActiveNav;
    const darkMode = useContext(ThemeContext).darkMode;

    // set global variable for active page
    useEffect(() => {
        setActiveNav('pilots');
    }, []);

    return(
        <div className="flex justify-center content-center pb-10">
            <div className={`w-screen sm:w-full py-5 px-10 ${darkMode ? txtGrayColor : txtDarkColor}`}>
                <h1 className={`text-center text-3xl font-bold pt-4 pb-2 ${darkMode ? txtWhiteColor : txtDarkColor}`}>Pilot Software</h1>
                <div className="flex justify-center content-center mb-5"><div className={`w-32 h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div></div>
                <section className="w-full flex justify-center content-center">
                    <div onMouseEnter={() => handleHover(".software-img", 0)} onMouseLeave={() => handleHoverLeave(".software-img", 0)} className="card-container flex flex-col justify-center content-center w-96 p-5 overflow-hidden hover:shadow-md hover:shadow-gray-600/40 transition duration-500 ease-in-out">
                        <div className="flex justify-center content-center">
                            <Link to="https://ivao.aero/softdev/software/altitude.asp" target="_blank"><img className="software-img w-80 transition duration-700 ease-in-out" src="/images/pilot/altitude.webp" alt="altitude"></img></Link>
                        </div>
                        <Link to="https://ivao.aero/softdev/software/altitude.asp" target="_blank"><h1 className={`text-2xl font-bold pt-6 pb-2 ${darkMode ? txtWhiteColor : txtDarkColor}`}>Pilot Client - Altitude</h1></Link>
                        <div className="min-h-60">
                            <h3 className="text-lg">Altitude, your new pilot client arrives with support for all current flight simulation platforms. Development is still happening and new features will keep being implemented and pumping. You can also count on a new and realistic web-based method to send your flight plans.</h3>
                        </div>
                        <div className="w-full flex justify-center my-2">
                            <Link to="https://ivao.aero/softdev/software/altitude.asp" target="_blank" ><button className={`text-lg font-bold focus:ring-4 focus:outline-none rounded-md flex justify-center content-center hover:scale-105 transition duration-100 px-20 py-1.5 ${txtWhiteColor} ${darkMode ? bgGrayColor : bgBlueColor} ${darkMode ? '' : 'shadow-md shadow-gray-600/40'}`}>download</button></Link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default PilotSoftware;