import { useContext, useEffect } from 'react';
import { NavContext, ThemeContext } from '../App';
import { bgGrayColor700, bgGrayColor, bgWhiteColor, txtDarkColor, txtWhiteColor, bgDarkColor } from "../style/colors";
import { tours, egEvents } from '../data/homeData';
import Carousel from "../components/carousel";
import HomeAdSection from "../components/home/homeAdSection";
import HomeSocialSection from "../components/home/homeSocialSection";
import HomeTourCard from "../components/home/homeTours";
import HomeEgEventCard from "../components/home/homeEgEvents";
// import HomeConnectionsSection from "../components/home/homeConnectionsSection";
// import HomeEventCard from "../components/home/homeEvents";
// import HomeTrainingCard from "../components/home/homeTraining";

const Home = () => {
    const setActiveNav = useContext(NavContext).setActiveNav;
    const darkMode = useContext(ThemeContext).darkMode;

    // set global variable for active page
    useEffect(() => {
        setActiveNav('home');
    }, []);

    return(
        <div className="flex justify-center content-center pb-10">
            <div className={`w-screen sm:w-full ${darkMode ? bgDarkColor : bgWhiteColor}`}>
                {/*Carousel section*/}
                <section className={`w-full flex flex-col justify-center content-center`}>
                    <Carousel />
                </section>
                {/*Welcome section*/}
                {/* <section className={`w-full flex flex-col justify-center content-center text-center px-10 pt-2 pb-8`}>
                    <h1 className={`text-xl sm:text-3xl font-bold p-5 ${darkMode ? txtWhiteColor : txtDarkColor}`}>Welcome to IVAO Egypt</h1>
                    <h2 className={`text-lg md:text-xl font-bold py-2 ${darkMode ? txtRedColor : txtBlueColor}`}>This website is for Simulation purposes only and none of the Information onboard is to be used for real world Navigation.</h2>
                </section> */}
                {/*Training section*/}
                {/* <section className="w-full flex-col justify-center content-center p-5">
                    <h1 className={`text-2xl text-center font-bold pt-1 pb-2 ${darkMode ? txtWhiteColor : txtDarkColor}`}>Upcoming Training</h1>
                    <div className={`w-full h-px mb-2 ${darkMode ? bgGrayColor : bgGrayColor}`}></div>
                    <div className="w-full flex justify-center content-center">
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            {trainings.map((training) => {
                                return (<HomeTrainingCard {...training} key={training.id} data={{darkMode}} />);
                            })}
                        </div>
                    </div>
                </section> */}
                <section className="w-full flex flex-col sm:flex-row-reverse content-center justify-center sm:justify-between py-0 sm:py-5">
                    {/*EG Events section*/}
                    <section className={`w-full h-fit sm:w-fit flex-col justify-center content-start p-2 sm:ms-2 ${darkMode ? bgGrayColor700 : bgWhiteColor} ${darkMode ? '' : 'shadow-md shadow-gray-600/40'}`}>
                        <h1 className={`text-xl md:text-2xl text-center font-bold pt-1 pb-2 ${darkMode ? txtWhiteColor : txtDarkColor}`}>Upcoming Events</h1>
                        <div className={`w-full h-px mb-2 ${bgGrayColor}`}></div>
                        <div className="w-full flex justify-center content-center">
                            {/* <HomeEgEventCard {...egEvents[0]} key={egEvents[0].id} /> */}
                            <div className="flex flex-col justify-center content-center">
                                {egEvents.map((event) => {
                                    return (<HomeEgEventCard {...event} key={event.id} data={{darkMode}} />);
                                })}
                            </div>
                        </div>
                    </section>
                    {/*Tours section*/}
                    <section className={`w-full flex-col justify-center content-start pt-2 sm:pb-5 sm:px-2 mt-1 sm:mt-0 ${darkMode ? bgGrayColor700 : bgWhiteColor} ${darkMode ? '' : 'shadow-t shadow-md shadow-gray-600/40'}`}>
                        <h1 className={`text-xl md:text-2xl text-center font-bold pt-1 pb-2 ${darkMode ? txtWhiteColor : txtDarkColor}`}>Tours</h1>
                        <div className={`w-full h-px mb-2 ${darkMode ? bgGrayColor : bgGrayColor}`}></div>
                        <div className="w-full flex justify-center content-center">
                            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-1">
                                {tours.map((tour) => {
                                    return (<HomeTourCard {...tour} key={tour.id} data={{darkMode}} />);
                                })}
                            </div>
                        </div>
                    </section>
                </section>
                {/*IVAO Events section*/}
                {/* <section className="w-full flex-col justify-end content-center py-5">
                    <h1 className={`text-2xl text-center font-bold pt-1 pb-2 ${darkMode ? txtWhiteColor : txtDarkColor}`}>IVAO Events</h1>
                    <div className={`w-full h-px mb-2 ${darkMode ? bgGrayColor : bgGrayColor}`}></div>
                    <div className="w-full flex justify-center content-center">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                            {ivaoEvents.map((event) => {
                                return (<HomeEventCard {...event} key={event.id} data={{darkMode}} />);
                            })}
                        </div>
                    </div>
                </section> */}
                <section className="min-h-32 sm:min-h-48 md:min-h-80 lg:min-h-96 w-full">
                    <img className="" src="images/home/fs2020-msr.jpg" alt="ivao-eg-section"></img>
                </section>
                {/*why choose us section*/}
                <HomeAdSection data={{darkMode}} />
                {/* Network activity section */}
                {/* <HomeConnectionsSection data={{darkMode}} /> */}
                {/* Social links section */}
                <HomeSocialSection data={{darkMode}} />

                {/* Load Images to improve performance */}
                <section className='hidden'>
                    <div className="static bottom-0 flex justify-end">
                        <img src="ivao.svg" className="w-1" alt="imgload"></img>
                        <img src="plane.png" className="w-1" alt="imgload"></img>
                        {darkMode ? <img src="EG.svg" className="w-1" alt="imgload"></img> : <img src="EG-white.svg" className="w-1" alt="imgload"></img>}
                        <img src="icons-social/icon-facebook-active.svg" className="w-1" alt="imgload"></img>
                        {darkMode ? <img src="icons-social/icon-facebook.svg" className="w-1" alt="imgload"></img> : <img src="icons-social/icon-facebook-white.svg" className="w-1" alt="imgload"></img>}
                        <img src="icons-social/icon-discord-active.svg" className="w-1" alt="imgload"></img>
                        {darkMode ? <img src="icons-social/icon-discord.svg" className="w-1" alt="imgload"></img> : <img src="icons-social/icon-discord-white.svg" className="w-1" alt="imgload"></img>}
                        <img src="icons-social/icon-instagram-active.svg" className="w-1" alt="imgload"></img>
                        {darkMode ? <img src="icons-social/icon-instagram.svg" className="w-1" alt="imgload"></img> : <img src="icons-social/icon-instagram-white.svg" className="w-1" alt="imgload"></img>}
                        <img src="icons-social/icon-youtube-active.svg" className="w-1" alt="imgload"></img>
                        {darkMode ? <img src="icons-social/icon-youtube.svg" className="w-1" alt="imgload"></img> : <img src="icons-social/icon-youtube-white.svg" className="w-1" alt="imgload"></img>}
                        <img src="icons-social/icon-envelope-active.svg" className="w-1" alt="imgload"></img>
                        {darkMode ? <img src="icons-social/icon-envelope.svg" className="w-1" alt="imgload"></img> : <img src="icons-social/icon-envelope-white.svg" className="w-1" alt="imgload"></img>}
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Home;