import { useState } from "react";
import { Link } from "react-router-dom";
import { txtDarkColor, txtLightBlueColor, txtRedColor, txtWhiteColor } from "../../style/colors";
// import { handleHover, handleHoverLeave } from "../../functions/cardAnimation";

const HomeEgEventCard = (props) => {

    const { img, title, info, alt, link} = props;
    const {darkMode} = props.data;
    const [seeMore, setSeeMore] = useState(false);
    const textLimit = 700;

    // Seperate line component
    const InfoLine = (props) => {
        return <h3 className="text-sm px-2 pt-2">{props.data}</h3>
    }
    // Function to limit the information on the event card if length > textLimit
    function checkInfo(info){
        if(info && info.length > textLimit){
            return info.slice(0, textLimit)+"...";
        }else{
            return info;
        }
    }

    return(
        <div className={`event-card-container min-w-80 max-w-80 sm:min-w-48 sm:max-w-48 md:min-w-64 md:max-w-64 lg:min-w-80 lg:max-w-80 min-h-96 flex flex-col m-1`}>
            <div className={`event-card w-full h-full flex flex-col overflow-hidden ${darkMode ? txtWhiteColor : txtDarkColor}`}>
                <Link to={link} target="_blank"><img className="eg-event-img w-full min-h-60 max-h-60 sm:min-h-44 sm:max-h-44 md:min-h-48 md:max-h-48 transition duration-700 ease-in-out" src={img} alt={alt}></img></Link>
                <div className="w-full h-fit flex flex-col justify-start content-center">
                    <h1 className="text-lg font-bold px-1 pt-2">{title}</h1>
                    {!seeMore && info.length <= textLimit && checkInfo(info).split(". ").map((line) => {return <InfoLine data={line} key={checkInfo(info).indexOf(line)} />})}
                    {!seeMore && info.length > textLimit && <h3 className="text-sm px-2 py-1">{checkInfo(info).split(". ").map((line) => {return <InfoLine data={line} key={checkInfo(info).indexOf(line)} />})}<button onClick={() => setSeeMore(true)} className={`${darkMode ? txtRedColor : txtLightBlueColor} float-right`}>see more</button></h3>}
                    {seeMore && info.split(". ").map((line) => {return <InfoLine data={line} key={checkInfo(info).indexOf(line)} />})}
                </div>
                <h3 className="text-right text-sm sm:text-xs px-2 my-2">For more information click <span><Link className={`${darkMode ? txtRedColor : txtLightBlueColor}`} to={link} target="_blank">here</Link></span>.</h3>
            </div>
        </div>
    );

}

export default HomeEgEventCard;