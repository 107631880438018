import { useContext, useEffect } from 'react';
import { NavContext, ThemeContext } from '../App';
import { txtDarkColor, bgRedColor, bgBlueColor, txtGrayColor } from '../style/colors';

const GuidelinesVFR = () => {
    const setActiveNav = useContext(NavContext).setActiveNav;
    const darkMode = useContext(ThemeContext).darkMode;
    
    // set global variable for active page
    useEffect(() => {
        setActiveNav('pilots');
    }, []);

    return(
        <div className="flex justify-center content-center pb-10">
            <div className={`w-screen sm:w-full py-5 px-10 ${darkMode ? txtGrayColor : txtDarkColor}`}>
                <section className="w-full flex flex-col justify-center content-center">
                    <h1 className="text-center text-2xl font-bold pt-6 pb-2">Guidelines VFR</h1>
                    <div className="flex justify-center content-center mb-5"><div className={`w-32 h-0.5 ${darkMode ? bgRedColor : bgBlueColor}`}></div></div>
                    <h2 className="text-center text-xl font-bold pb-6">Pilot Guidelines – Visual Flight Rules (VFR)</h2>
                    <ol className="list-disc mb-6 ps-4 text-lg">
                        <li><h3>For a VFR flight in real life, you do not have to file a flightplan. In our virtual world however, a flightplan should always be filed.</h3></li>
                        <li><h3>VFR stands for Visual Flight Rules. The pilot must fly and navigate with visual reference to the surface. Due to this, there are certain weather criteria that must be met for a VFR flight to take place.</h3></li>
                        <li><h3>VFR flights are not permitted in Class A airspace, so there are no criteria.</h3></li>
                        <li><h3 className="">At or above 10.000FT :</h3>
                            <ol className="list-disc text-base ps-8">
                                <li>1,500 metres horizontally away from cloud</li>
                                <li>1,000ft vertically away from cloud</li>
                                <li>In flight visibility of at least 8km</li>
                            </ol>
                        </li>
                        <li><h3 className="">Below 10.000FT:</h3>
                            <ol className="list-disc text-base ps-8">
                                <li>1,500 metres horizontally away from cloud</li>
                                <li>1,000ft vertically away from cloud</li>
                                <li>In flight visibility of at least 5km</li>
                            </ol>
                        </li>
                        <li><h3 className="">An aircraft is deemed to have complied with the below 10.000FT restrictions if the aircraft is not a helicopter and:</h3>
                            <ol className="list-disc text-base ps-8">
                                <li>it flies at or below 3,000ft amsl</li>
                                <li>it flies at a speed which is 140KIAS or less</li>
                                <li>remains clear of cloud, with the surface in sight and an in flight visibility of at least 5km</li>
                            </ol>
                        </li>
                        <li><h3 className="">If it is a helicopter, it is deemed to have complied with the below 10.000FT restrictions if:</h3>
                            <ol className="list-disc text-base ps-8">
                                <li>it flies at or below 3,000ft amsl</li>
                                <li>it remains clear of cloud, with the surface in sight and an in flight visibility of at least 1,500 meter</li>
                            </ol>
                        </li>
                    </ol>
                </section>
                <section className={`w-full flex flex-col justify-center content-center ${txtDarkColor}`}>
                    <table className="mb-4 shadow-md shadow-gray-600/40">
                        <tbody>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="w-28 text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Port Said tower, good morning, SU-ABC, A Cessna 172 with information Delta at the general aviation apron , request atc and taxi clearance for VFR flight to El Arish .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HEPS_TWR :</td>
                                <td className="ps-3">SU-ABC , Cleared for VFR flight to El Arish , follow the coast line, Not above altitude 3,500ft, squawk 7001.</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Cleared VFR to El Arish ,follow coast line, not above altitude 3,500ft, squawk 7001, SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HEPS_TWR :</td>
                                <td className="ps-3">SU-ABC, Correct,Taxi and hold short rwy 10 via A QNH 1015 .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Taxi and hold short rwy 10 via A QNH 1015 , SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">At holding point A rwy 10 , SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HEPS_TWR :</td>
                                <td className="ps-3">SU-ABC , Taxi backtrack rwy 10 , report ready for departure .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Taxi backtrack rwy 10 , will report ready for departure , SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Port Said tower, Ready for departure , SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HEPS_TWR :</td>
                                <td className="ps-3">SU-ABC , wind 060 degrees at 7 knots, rwy 10 cleared for take off, report airborne .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Cleared for take off rwy 10 , will report airborne, SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Airborne rwy 10 , SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HEPS_TWR :</td>
                                <td className="ps-3">SU-ABC , left turn to the coast line, maintain altitude 3,500ft</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Left turn to the coast, climb and maintain 3,500ft , SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HEPS_TWR :</td>
                                <td className="ps-3">Continue on the coast line, contact Cairo center frequency 125.300 .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Continue on the coast line, contact Cairo Control 125.300 , SU-ABC .</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mb-4 shadow-md shadow-gray-600/40">
                        <tbody>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="w-28 text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Cairo Control, SU-ABC with you VFR at 3,500ft , following the coast line, east bound .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HECC_CTR :</td>
                                <td className="ps-3">SU-ABC , maintain 3,500ft, continue east bound on the coast line, report 40nm from ARH VOR .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Cairo Control , SU-ABC reporting 40nm from ARH VOR .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HECC_CTR :</td>
                                <td className="ps-3">SU-ABC , Contact El Arish approach frequency 123.720 .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">El Arish approach 123.720 , SU-ABC .</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mb-4 shadow-md shadow-gray-600/40">
                        <tbody>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="w-28 text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">El Arish approach , SU-ABC with you VFR at 3,500ft , following the coast line, east bound .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HEAR_APP :</td>
                                <td className="ps-3">SU-ABC , continue east bound on the coast line , report El Arish airfield insight .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">continue east bound , will report El Arish airfield insight , SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">El Arish airfield insight , SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HEAR_APP :</td>
                                <td className="ps-3">SU-ABC , Decand ALT 1500ft AGL , QNH 1012 Contact El Arish Tower on 121.000</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Descent ALT 1500ft AGL ,QNH 1012 , Contact Tower on 121.000 , SU-ABC .</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mb-4 shadow-md shadow-gray-600/40">
                        <tbody>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="w-28 text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">El Arish Tower , SU-ABC , ALT 1500ft AGL .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HEAR_TWR :</td>
                                <td className="ps-3">SU-ABC , join the left downwind rwy 34 ,report ready for base leg .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">join the left downwind rwy 34 , will report ready for base leg .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">request base leg , SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HEAR_TWR :</td>
                                <td className="ps-3">SU-ABC , join the base leg rwy 34, report on final, SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">Final RWY 34 , SU-ABC .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1 border-b border-gray-200">
                                <td className="text-center font-bold bg-gray-400">HEAR_TWR :</td>
                                <td className="ps-3">SU-ABC , wind 320 degrees at 8 knots, rwy 34 cleared to land .</td>
                            </tr>
                            <tr className="h-12 bg-gray-300 p-1">
                                <td className="text-center text-blue-700 font-bold bg-gray-400">SU-ABC :</td>
                                <td className="ps-3">cleared to land RWY 34 , SU-ABC .</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div>
        </div>
    );
}

export default GuidelinesVFR;