import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon } from '@fortawesome/free-solid-svg-icons';
import { bgWhiteColor, bgBlueColor, txtDarkColor, txtWhiteColor, bgWhiteGrayColor, bgDarkGrayColor, txtDarkGrayColor } from "../../style/colors";


const DarkMode = (props) => {
    const {darkMode, setDarkMode} = props.data;

    const darkModeFunction = () => {
        setDarkMode(true);
        localStorage.setItem('darkMode', 'true');
    }
    const lightModeFunction = () => {
        setDarkMode(false);
        localStorage.setItem('darkMode', 'false');
    }

    return(
        <div onClick={() => {darkMode ? lightModeFunction() : darkModeFunction()}} className={`w-14 h-7 rounded-2xl relative flex flex-col justify-center cursor-pointer shadow-inner shadow-gray-500 ${darkMode ? bgDarkGrayColor : bgWhiteGrayColor}`}>
            <div className={`h-6 w-6 rounded-2xl mx-0.5 absolute flex flex-col justify-center content-center shadow-sm shadow-gray-500 transition duration-300 ease-in-out ${darkMode ? bgWhiteColor : bgBlueColor} ${darkMode ? 'right-0' : 'left-0'}`}>{darkMode ? <FontAwesomeIcon className={`text-sm ${darkMode ? txtDarkColor : txtWhiteColor}`} icon={faMoon} /> : <img className='w-4 mx-auto' src='lightMode.svg' alt='light-Mode'></img>}</div>
            <div className={`h-6 w-6 mx-1 absolute flex flex-col justify-center content-center transition duration-300 ease-in-out ${darkMode ? 'left-0' : 'right-0'}`}>{darkMode ? <img className='w-4 mx-auto' src='lightMode.svg' alt='light-Mode'></img> : <FontAwesomeIcon className={`text-sm ${darkMode ? txtDarkGrayColor : txtDarkColor}`} icon={faMoon} />}</div>
        </div>
    );

}

export default DarkMode