import { handleHover, handleHoverLeave } from "../../functions/cardAnimation";
import { Link } from "react-router-dom";
import { bgBlueColor, bgWhiteGrayColor, txtDarkColor, txtWhiteColor } from "../../style/colors";

const HomeTourCard = (props) => {

    const {id, link, img, title, year, alt} = props;
    const {darkMode} = props.data;

    const textAnimation = (x, id) => {
        const titleText = document.querySelectorAll(".title-text")[id];
        const yearText = document.querySelectorAll(".year-text")[id];
        titleText.style.transition = "all 1s ease-in-out";
        yearText.style.transition = "all 1s ease-in-out";
        if(x){
            titleText.style.transform = "scale(120%)";
            yearText.style.transform = "scale(110%)";
        }else{
            titleText.style.transform = "scale(100%)";
            yearText.style.transform = "scale(100%)";
        }
    }

    return(
        <Link to={link} target="_blank" onMouseEnter={() => {handleHover(".tour-img", id-1); textAnimation(true, id-1);}} onMouseLeave={() => {handleHoverLeave(".tour-img", id-1); textAnimation(false, id-1);}} className={`tour-card-container min-h-52 max-h-52 sm:min-h-56 sm:max-h-56 md:min-h-64 md:max-h-64 xl:min-h-64 xl:max-h-64 flex flex-col hover:shadow-sm hover:shadow-gray-800/40 transition duration-500 ease-in-out m-1 ${darkMode ? bgWhiteGrayColor : bgBlueColor}`}>
            <div className={`tour-card relative w-full h-full flex flex-col overflow-hidden ${darkMode ? txtDarkColor : txtWhiteColor}`}>
                <img className="tour-img w-full min-h-32 max-h-32 sm:min-h-32 sm:max-h-32 md:min-h-44 md:max-h-44 lg:min-h-48 lg:max-h-48 xl:min-h-48 xl:max-h-48 transition duration-700 ease-in-out" src={img} alt={alt}></img>
                <div className="w-full h-full flex flex-col justify-center content-center px-0.5">
                    <h1 className="title-text text-center">{title}</h1>
                    <h1 className="year-text text-center">{year}</h1>
                    {/* <div className="absolute top-0 pt-20">
                        <h3 className="text-sm sm:text-xs md:text-sm xl:pb-2 text-gray-200 px-1">Every flight must include:</h3>
                        <div className="w-fit flex flex-col justify-center content-center p-1"><h3 className="w-fit text-xs text-gray-900 font-bold bg-gray-300 p-1">{rmk}</h3></div>
                        <h3 className="text-sm sm:text-xs md:text-sm xl:pt-2 text-gray-200 px-1">in the Remarks section of your Flight Plan</h3>
                    </div> */}
                </div>
            </div>
        </Link> 
    );

}

export default HomeTourCard;