import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleChevronRight, faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { ThemeContext } from '../App';
import { txtDarkColor, txtDarkGrayColor, txtWhiteColor } from '../style/colors';

const sliders = [
    {id:1, img:"sliders/ivao.jpg", alt:"ivao"},
    {id:2, img:"sliders/career.jpg", alt:"ivao-pilots-career"},
    {id:3, img:"sliders/atco.jpg", alt:"ivao-atc-career"},
    {id:4, img:"sliders/community.jpg", alt:"ivao-community"}
];

const Carousel = () => {
    const darkMode = useContext(ThemeContext).darkMode;
    const [currentIndex, setCurrentIndex] = useState(1);
    const [carouselHovered, setcarouselHovered] = useState(false);

    useEffect(() => {
        // Autoplay Carousel
        const carouselInterval = setInterval(() => {
            if(!carouselHovered){
                goToNextSlide();
            }
        }, 7000);
        return () => {
            clearInterval(carouselInterval);
        };
    }, [currentIndex, carouselHovered]);

    // Carousel Fade effect Function
    function CarouselEffect(){
        const carouselImg = document.querySelector(".carousel-img");
        
        carouselImg.style.opacity = "0.90";
        carouselImg.style.transition = "opacity 1s ease-in-out";

        const carouselAnimation = setTimeout(() => {
            carouselImg.style.transition = "opacity 3s ease-in-out";
            carouselImg.style.opacity = "1";
        }, 1000);
        return () => {
            clearInterval(carouselAnimation);
        };
    }

    // Carousel Arrows effect Function
    function CarouselArrowEffect(){
        const carouselArrowR = document.querySelector(".carousel-arrow-r");
        const carouselArrowL = document.querySelector(".carousel-arrow-l");
        if(!carouselHovered){
            carouselArrowR.style.opacity = "1";
            carouselArrowL.style.opacity = "1";
            carouselArrowR.style.transition = "opacity 0.5s ease-in-out";
            carouselArrowL.style.transition = "opacity 0.5s ease-in-out";
        }else{
            carouselArrowR.style.opacity = "0";
            carouselArrowL.style.opacity = "0";
        }
    }

    // Next Carousel Image Function
    const goToNextSlide = () => {
        if(sliders.length === currentIndex){
            setCurrentIndex(1);
        }else{
            setCurrentIndex(currentIndex + 1);
        }
        CarouselEffect();
    };
    
    // Prev Carousel Image Function
    const goToPrevSlide = () => {
        if(currentIndex === 1){
            setCurrentIndex(sliders.length);
        }else{
            setCurrentIndex(currentIndex - 1);
        }
        CarouselEffect();
    };

    // Carousel dots component
    const Dots = (props) => {
        return <li id={props.id} onClick={() => {setCurrentIndex(props.id)}}><FontAwesomeIcon icon={faCircle} className={`${props.id === currentIndex ? 'w-2 mx-1 px-1 py-1 cursor-pointer '+(darkMode ? txtWhiteColor : txtDarkColor) : 'w-2 mx-1 px-1 py-1 cursor-pointer '+txtDarkGrayColor}`} /></li>
    }

    return(
        <div onMouseEnter={() => {setcarouselHovered(true); CarouselArrowEffect()}} onMouseLeave={() => {setcarouselHovered(false); CarouselArrowEffect()}} className='flex flex-col justify-center content-center'>
            <div className='sm:min-h-48 md:min-h-80 lg:min-h-96 relative flex flex-col justify-center content-center'>
                <div onClick={goToNextSlide} className='carousel-arrow-r hidden md:block absolute z-30 right-0 me-5 opacity-0'>
                    <FontAwesomeIcon icon={faCircleChevronRight} className='text-gray-200 hover:text-gray-100 hover:scale-105 text-4xl cursor-pointer' />
                </div>
                <div onClick={goToPrevSlide} className='carousel-arrow-l hidden md:block absolute z-30 left-0 ms-5 opacity-0'>
                    <FontAwesomeIcon icon={faCircleChevronLeft} className='text-gray-200 hover:text-gray-100 hover:scale-105 text-4xl cursor-pointer' />
                </div>
                <img className='carousel-img w-full' src={sliders[currentIndex-1].img} alt={sliders[currentIndex-1].alt}></img>
            </div>
            {/* <div className='carouselPlaneContainer h-full w-full invisible flex items-center overflow-hidden -mt-4'><img className='carouselPlane w-10 drop-shadow-md pt-1.5 z-20' src='./plane1.png' alt="plane"></img><div className={`carouselContrail w-[500px] h-1 bg-gradient-to-l ${darkMode ? 'from-gray-400' : 'from-blue-700'} -ms-[510px] mt-2.5 z-10`}></div></div> */}
            <div className="dots hidden md:block">
                <ul className='flex justify-center content-center'>
                    {sliders && sliders.map((data) => {
                        return (
                            <Dots id={data.id} key={data.id} />
                        );
                    })}
                </ul>
            </div>
        </div>
    );

}

export default Carousel;